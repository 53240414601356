import { useCallback, useContext, useEffect, useState } from "react";
import { Box, Card, CardContent, CardHeader, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Tooltip, Icon } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import HelpIcon from "@mui/icons-material/Help";
import DropzoneArea from "components/DropzoneArea";
import { formatBytes } from "utils";
import { Dictionary, FileType, FileStatus, Metadata } from "types";
import { Info } from "@mui/icons-material"
import { useApi, useDeepState, useFileUploader, useLocalStorage } from "hooks";
import { MetadataTable } from "components/MetadataTable";
import { FileStatusDisplay } from "components/FileStatusDisplay"
import Feedback, { FeedbackProps } from "components/@beca-common-react-legacy/Feedback";
import { ConfigContext } from "context";
import { LocalStorageKey } from "hooks/useLocalStorage";

export type UploadFile = {
    id?: string;
    file: File;
    status: FileStatus;
    progress: number;
};

export default function Uploader() {

    const { concurrency, filesLimit, maxFileSize, getContextualMetadata, fileType, inputFields } = useContext(ConfigContext).config.upload;

    const [files, setFiles] = useDeepState<Dictionary<UploadFile>>({});
    const [metadatas, setMetadatas] = useDeepState(inputFields);
    const [feedback, setFeedback] = useState<FeedbackProps>();
    const [isSaving, setSaving] = useState(false);

    const api = useApi();
    const { queueUploadFile } = useFileUploader(concurrency);

    const { setValue: setNewFilesEvent } = useLocalStorage(LocalStorageKey.newFileEvent);

    const onBeforeUnload = useCallback((e: BeforeUnloadEvent) => {
        if (Object.values(files).length > 0)
            e.returnValue = {};
    }, []);

    const onUnload = useCallback((e: Event) => {
        if (Object.values(files).length === 0)
            return;

        var body = {
            fileIds: Object.values(files).map(x => x.id)
        }

        api.deleteFiles({
            input: body,
            onError: (error) => setFeedback({ message: error, severity: "error", neverHide: true })
        })
    }, []);

    useEffect(() => {
        window.addEventListener("beforeunload", onBeforeUnload, false);
        window.addEventListener("unload", onUnload, false);

        return () => {
            window.removeEventListener("beforeunload", onBeforeUnload);
            window.removeEventListener("unload", onUnload);
        }
    });

    return (
        <>
            <Feedback {...feedback} />

            {/* Drag and Drop component */}
            <Box m={2} color="grey">
                <Card variant="outlined">

                    <CardHeader title="Upload" />
                    <CardContent>
                        <DropzoneArea
                            onAccept={onAccept}
                            onError={(error: Error) => setFeedback({ message: error.message, severity: "error", neverHide: true })}
                            fileTypes={getAcceptedFiles()?.join(",")}
                            filesLimit={filesLimit}
                            maxFileSize={maxFileSize}
                        />
                    </CardContent>
                </Card>
            </Box>

            {/* Tags/Metadata table */}
            <Box m={2}>
                <Card variant="outlined">
                    <CardContent>
                        <Box alignItems="center" display="flex" gap={1}>
                            <Typography variant="h5" component="span">
                                Add Metadata
                            </Typography>
                            <Typography variant="body1" component="span" color="gray">
                                (Optional)
                            </Typography>
                            <Tooltip title="Metadata help us better manage information in Collections (groups) and enables searching and sorting in future." placement="right">
                                <Box component="span" color="gray">
                                    <Icon>
                                        <HelpIcon />
                                    </Icon>
                                </Box>
                            </Tooltip>
                        </Box>

                        <MetadataTable
                            metadatas={metadatas}
                            onChange={setMetadatas}
                            allowDelete={false}
                            allowNew={true}
                            allowEditKeys={false}
                            allowEditValues={true} />
                        <Box mt={1} color="gray" display="flex" alignItems="center" gap={1}>
                            <Info />
                            <Typography display="inline">Metadata will be applied to files(s) upon finishing this operation</Typography>
                        </Box>
                    </CardContent>
                </Card>
            </Box>

            {/* Control buttons */}
            <Box m={2}>
                <Grid container justifyContent="flex-end">
                    <LoadingButton
                        loading={isSaving}
                        color="primary"
                        variant="contained"
                        startIcon={<SaveIcon />}
                        loadingPosition="start"
                        disabled={IsFinishDisabled()}
                        onClick={onFinish}>
                        Finish
                    </LoadingButton >
                </Grid>
            </Box>

            {/* Upload files table */}
            {Object.keys(files).length > 0 &&
                <Box m={2}>
                    <Card variant="outlined">
                        <CardHeader title="Uploaded files" />
                        <CardContent>
                            <FileTable files={files} />
                        </CardContent>
                    </Card>
                </Box>
            }
        </>
    );

    function IsFinishDisabled() {
        const filesArray = Object.values(files)

        return filesArray.length === 0 || filesArray.some(f => f.status === FileStatus.Uploading || f.status === FileStatus.Pending);
    }

    async function onAccept(acceptedFiles: File[]) {

        acceptedFiles.forEach(droppedFile => {
            files[droppedFile.name] = {
                file: droppedFile,
                status: FileStatus.Pending,
                progress: 0
            }
        })
        setFiles(files);

        await acceptedFiles.forEach(async file => {
            await queueUploadFile(file,
                {
                    onStart: (file) => setStatus(file, FileStatus.Uploading),
                    onProgress: setProgress,
                    onComplete: (file) => setStatus(file, FileStatus.Completed),
                    onError: (file, error) => setStatus(file, FileStatus.UploadFailed),
                })
                .then(fileId => {
                    updateFile({
                        ...files[file.name],
                        id: fileId
                    });
                });
        });

    }

    function onFinish() {

        setSaving(true);

        const postFilesWithMetadata = (contextualMetadata: Metadata[]) => {
            const fileIds = Object.values(files).map(x => x.id);

            api.postMetadatas({
                input: {
                    fileIds,
                    metadatas: [...(contextualMetadata || []), ...(metadatas || [])],
                    startFileProcessing: true
                },
                onSuccess,
                onError: (error) => {
                    setFeedback({ message: error, severity: "error", neverHide: true })
                    setSaving(false);
                }
            })
        }

        const onError = (error: string) => {
            const message = `Your files will be uploaded, however there was an error getting contextual metadata: ${error}`;
            setFeedback({ message, severity: "warning", neverHide: true })
            postFilesWithMetadata([]);
        }

        getContextualMetadata({ onSuccess: postFilesWithMetadata, onError })

        function onSuccess() {
            //TODO replace with SIGNAL-R as part of item #82693
            setNewFilesEvent(JSON.stringify(files));

            window.removeEventListener("beforeunload", onBeforeUnload);
            window.removeEventListener("unload", onUnload);
            window.close();
        }
    }

    function setStatus(file: File, status: FileStatus) {
        updateFile({
            ...files[file.name],
            status
        });
    }

    function setProgress(file: File, progress: number) {
        updateFile({
            ...files[file.name],
            status: FileStatus.Uploading,
            progress
        });
    }

    function updateFile(uploadFile: UploadFile) {
        files[uploadFile.file.name] = {
            ...files[uploadFile.file.name],
            ...uploadFile
        };
        setFiles(files);
    }

    //Documentation reference : https://react-dropzone.js.org/?_sm_au_=iVVHPSFbL6PQ4RNDNBL7NKHtMcHWC#section-accepting-specific-file-types
    function getAcceptedFiles() {
        switch (fileType) {
            case FileType.Image:
                return ["image/*"];
            case FileType.Video:
                return ["video/*"];
            case FileType.Audio:
                return ["audio/*"];
            case FileType.PointCloud:
                return [".slpk"];
            case FileType.Pdf:
                return [".pdf"];
        }
    }
}

function FileTable({ files }: { files: Dictionary<UploadFile> }) {
    return (
        <TableContainer >
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>File</TableCell>
                        <TableCell align="right">Size</TableCell>
                        <TableCell align="right">Type</TableCell>
                        <TableCell align="right"></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {Object.values(files).map((x) => (
                        <TableRow key={x.file.name} >
                            <TableCell>{x.file.name}</TableCell>
                            <TableCell align="right">{formatBytes(x.file.size, 0)}</TableCell>
                            <TableCell align="right">{x.file.type}</TableCell>
                            <TableCell align="right">
                                <FileStatusDisplay status={x.status} progress={x.progress} />
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
