import { HubConnectionBuilder } from "@microsoft/signalr";
import { TenantContext, } from "context";
import { useIdentity } from "hooks";
import { useContext, useEffect } from "react";
import { FilesHubMethods } from "types";

export type SignalRCallbacks = {
    [methodName: string]: (...args: any[]) => void
}

export default function useSignalR(hubName: string, callbacks: SignalRCallbacks) {

    const { getIdentity } = useIdentity();
    const tenant = useContext(TenantContext).tenant;

    useEffect(() => {

        getIdentity()
            .then((identity) => {

                const connection = new HubConnectionBuilder()
                    .withUrl(`${(window as any).REACT_APP_SIGNALR_URL}/${hubName}`, {
                        accessTokenFactory: () => identity?.token
                    })
                    .withAutomaticReconnect()
                    .build();

                connection.start().then(() => {
                    connection.send(FilesHubMethods.Subscribe, tenant?.path);
                    for (const [methodName, action] of Object.entries(callbacks))
                        connection.on(methodName, action);
                });

                return () => {
                    connection.stop();
                }
            });
    }, []);
};
