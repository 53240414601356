import Dropzone, { DropzoneInputProps, DropzoneRootProps, FileRejection } from "react-dropzone"
import { Theme, Typography } from "@mui/material";
import { makeStyles } from 'tss-react/mui';
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

export interface DropzoneAreaProps {
    onAccept: (files: File[]) => void,
    onError: (error: Error) => void,
    filesLimit: number,
    fileTypes: string,
    maxFileSize: number,
}

interface DropzoneAreaVisualProps {
    getRootProps: (props?: DropzoneRootProps) => DropzoneRootProps,
    getInputProps: (props?: DropzoneInputProps) => DropzoneInputProps,
    isDragReject: boolean,
    isDragActive: boolean
}

const useStyles = makeStyles()(({ palette, shape, spacing }: Theme) => ({
    root: {
        position: 'relative',
        width: '100%',
        minHeight: '250px',
        backgroundColor: palette.background.paper,
        border: 'dashed',
        borderColor: palette.divider,
        borderRadius: shape.borderRadius,
        boxSizing: 'border-box',
        cursor: 'pointer',
        overflow: 'hidden',
    },
    active: {
        animation: '$progress 2s linear infinite !important',
        // eslint-disable-next-line max-len
        backgroundImage: `repeating-linear-gradient(-45deg, ${palette.background.paper}, ${palette.background.paper} 25px, ${palette.divider} 25px, ${palette.divider} 50px)`,
        backgroundSize: '150% 100%',
        border: 'solid',
        borderColor: palette.primary.light,
    },
    invalid: {
        // eslint-disable-next-line max-len
        backgroundImage: `repeating-linear-gradient(-45deg, ${palette.error.light}, ${palette.error.light} 25px, ${palette.error.dark} 25px, ${palette.error.dark} 50px)`,
        borderColor: palette.error.main,
    },
    textContainer: {
        textAlign: 'center',
    },
    text: {
        marginBottom: spacing(3),
        marginTop: spacing(3),
    },
    icon: {
        width: 51,
        height: 51,
        color: palette.text.primary,
    },
}));

function DropzoneAreaVisual({ getRootProps, getInputProps, isDragReject, isDragActive }: DropzoneAreaVisualProps) {

    const { classes, cx } = useStyles();

    return (
        <div {...getRootProps({
            className: cx(
                classes.root,
                isDragActive && classes.active,
                isDragReject && classes.invalid,
            )
        })}>
            <input {...getInputProps()} />
            <div className={classes.textContainer}>
                <Typography variant="h5" component="p" className={classes.text}>
                    Drag and drop file(s) here or click to open a file browser
                </Typography>
                <CloudUploadIcon className={classes.icon} />
            </div>
        </div>);
}

export default function DropzoneArea({ onAccept, onError, fileTypes, filesLimit, maxFileSize }: DropzoneAreaProps) {

    return (
        <Dropzone
            onDropAccepted={onAccept}
            onDropRejected={onReject}
            accept={fileTypes}
            maxFiles={filesLimit}
            maxSize={maxFileSize}
            useFsAccessApi={false}
        >
            {DropzoneAreaVisual}
        </Dropzone>);

    function onReject(rejections: FileRejection[]) {
        if (rejections.length > filesLimit) {
            const errorMessage = `Maximum allowed number of files exceeded. Only ${filesLimit} allowed`;
            onError({ name: "too-many-files", message: errorMessage });
            return;
        }
        const errorMessage = rejections.map(rejection => {
            if (rejection.errors.length === 0)
                return;
            const errorReasonMessage = rejection.errors.map(error => {
                switch (error.code) {
                    case "file-too-large":
                        return `File is too big. Size limit is ${maxFileSize} bytes.`;
                    case "file-invalid-type":
                        return `File type not supported.`;
                    default:
                        return `${error.message}`;
                }
            }).join(", ");
            return `File ${rejection.file.name} was rejected: ${errorReasonMessage}`;
        }).join("\n ");
        onError({ name: "files-not-supported", message: errorMessage });
        return
    };
}