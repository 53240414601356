import { SvgIconComponent } from "@mui/icons-material";
import { IconButton, IconButtonProps, Tooltip } from "@mui/material";
import { useIntl } from "react-intl";


export type TooltipIconButtonProps = {
    Icon: SvgIconComponent,
    buttonId?: string,
} & IconButtonProps

export type TooltipIconButtonVisualProps = {
    Icon: SvgIconComponent,
    tooltip: string
} & IconButtonProps

export function TooltipIconButtonVisual({ Icon, tooltip, ...buttonProps }: TooltipIconButtonVisualProps) {
    return (
        <Tooltip title={tooltip}>
            <span> {/* Mui Tooltip needs a non-disabled element to detect hover*/}
                <IconButton {...buttonProps}>
                    <Icon />
                </IconButton>
            </span>
        </Tooltip>
    );
}

export default function TooltipIconButton({ Icon, buttonId, ...buttonProps }: TooltipIconButtonProps) {

    const intlId = `iconButton.${buttonId}.tooltip`;
    var tooltip = intlId;
    try {
        const intl = useIntl();
        tooltip = intl.formatMessage({
            id: intlId
        });
    }
    catch {
        console.warn("intl is not defined in the component ancestry")
    }

    return <TooltipIconButtonVisual Icon={Icon} tooltip={tooltip} {...buttonProps} />;
}
