import "pannellum/src/js/pannellum";
import "pannellum/src/js/libpannellum";
import "pannellum/src/css/pannellum.css";
import { useEffect } from "react";
import { Box } from "@mui/material";
import { Pannellum } from "./types";

const pannellum: Pannellum = (window as any).pannellum;

const panoramaElementId = "panorama"

export function PanoramaViewer({ url }: { url: string }) {

    useEffect(() => {
        pannellum.viewer(panoramaElementId, {
            type: "equirectangular",
            panorama: url,
            autoLoad: true
        });
    }, [])

    return (
        <Box display="flex" height="100%" width="100%">
            <Box margin="auto"
                sx={{
                    aspectRatio: "1/1",
                    maxAspectRatio: "2/1"
                }}
                maxHeight="100%"
                maxWidth="100%"
                flexGrow={1}
            >
                <Box className="panorama" id={panoramaElementId} />
            </Box>
        </Box>
    );
}