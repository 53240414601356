const localStorageUtility = {  
    getItem: function (key: string): string | null {  
        try {  
            return localStorage.getItem(key);  
        } catch (error) {  
            console.error(`Error getting item ${key} from localStorage`, error);  
            return null;  
        }  
    },  
    setItem: function (key: string, value: any): void {  
        try {  
            localStorage.setItem(key, value);  
        } catch (error) {  
            console.error(`Error setting item ${key} to localStorage`, error);  
        }  
    },  
    removeItem: function (key: string): void {  
        try {  
            localStorage.removeItem(key);  
        } catch (error) {  
            console.error(`Error removing item ${key} from localStorage`, error);  
        }  
    },  
    clear: function (): void {  
        try {  
            localStorage.clear();  
        } catch (error) {  
            console.error(`Error clearing localStorage`, error);  
        }  
    },  
  };  
  
  const sessionStorageUtility = {  
    getItem: function (key: string): string | null {  
        try {  
            return sessionStorage.getItem(key);  
        } catch (error) {  
            console.error(`Error getting item ${key} from sessionStorage`, error);  
            return null;  
        }  
    },  
    setItem: function (key: string, value: any): void {  
        try {  
            sessionStorage.setItem(key, value);  
        } catch (error) {  
            console.error(`Error setting item ${key} to sessionStorage`, error);  
        }  
    },  
    removeItem: function (key: string): void {  
        try {  
            sessionStorage.removeItem(key);  
        } catch (error) {  
            console.error(`Error removing item ${key} from sessionStorage`, error);  
        }  
    },  
    clear: function (): void {  
        try {  
            sessionStorage.clear();  
        } catch (error) {  
            console.error(`Error clearing sessionStorage`, error);  
        }  
    },  
  };  
  
  export { localStorageUtility, sessionStorageUtility };  
  