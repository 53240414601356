import { useContext, useEffect, useState } from "react";
import { LoaderContainer } from "utils";
import { FileDto, Metadata } from "types";
import { useApi, useDeepState } from "hooks";
import { ConfigContext } from "context/configContext";
import { clone } from "lodash";
import { FileEditForm } from "components/ui/FileEditForm";
import Feedback, { FeedbackProps } from "components/@beca-common-react-legacy/Feedback";


export type SingleFileEditProps = {
    fileId: string,
    onSuccess?: () => void,
    onCancel?: () => void,
}

export function SingleFileEdit({ fileId, onSuccess, onCancel }: SingleFileEditProps) {

    const api = useApi();
    const { inputFields } = useContext(ConfigContext).config.upload;

    const [feedback, setFeedback] = useState<FeedbackProps>();
    const [state, setState] = useState<{ error?: string; isSaving?: boolean; }>({});
    const [data, setData] = useDeepState<{ name?: string; metadatas?: Metadata[]; }>();

    useEffect(() => {
        api.getFile({
            input: { fileId },
            onSuccess: onMetadataLoad,
            onError: (error) => setState({ ...state, error })
        });
    }, [fileId]);

    return (
        <>
            <Feedback {...feedback} />
            <LoaderContainer loaded={data} error={state.error}>
                <FileEditForm data={data} onChange={setData} onSave={onSave} isSaving={state.isSaving} onCancel={onCancel} />
            </LoaderContainer>
            </>

    );

    function onMetadataLoad(file: FileDto) {

        var edit = clone(inputFields);

        file.metadatas.forEach(apiMetadata => {
            const metadata = inputFields.find(x => x.key === apiMetadata.key);
            if (metadata) {
                metadata.value = apiMetadata.value;
                metadata.system = apiMetadata.system;
                return;
            }
            edit.push(apiMetadata);
        });

        setData({ name: file.name, metadatas: edit });
    }

    function onSave() {
        setState({ ...state, isSaving: true });
        api.postFile({
            input: {
                id: fileId,
                name: data.name,
                metadata: data.metadatas
            },
            onSuccess,
            onError: (error) => 
            {
                setFeedback({ message: error, severity: "error", neverHide: true })
                setState({ ...state, isSaving: false });
            }
        });
    }
}
