import { useContext, useEffect, useState } from "react";
import { TenantContext } from "context";
import { useApi } from "hooks";

export default function Home() {

    const tenant = useContext(TenantContext).tenant
    const [apiHelloMessage, setApiHelloMessage] = useState(null);

    const api = useApi();

    useEffect(() => {
        api.hello({
            onSuccess: (d: { message: string }) => setApiHelloMessage(d.message),
            onError: console.log
        });
    });

    return (
        <>
            <div>CLIENT TENANT: {tenant.path}</div>
            <div>API HELLO: {apiHelloMessage}</div>
        </>
    );
}


