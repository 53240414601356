import { ConfigContext, TenantContext } from "context";
import { getAuthDomain } from "hooks/useIdentity/useApiIdentity";
import { useContext } from "react";
import { MetadataDto as Metadata } from "types";

interface UploaderParams {
    metadata?: Metadata[];
}

export default function useSpaTenantRoute() {

    const tenant = useContext(TenantContext).tenant;
    const authDomain = getAuthDomain();

    const { concurrency, maxFileSize, filesLimit, fileType, inputFields } = useContext(ConfigContext).config.upload;

    const Uploader = ({ metadata }: UploaderParams) => {
        const url = new URL(`http://dummyurl/${tenant.path}/uploader`);
        addSearchParams('metadata', JSON.stringify(metadata))
        addSearchParams('concurrency', concurrency);
        addSearchParams('maxFileSize', maxFileSize);
        addSearchParams('filesLimit', filesLimit);
        addSearchParams('fileType', fileType);
        addSearchParams('inputFields', JSON.stringify(inputFields));
        addSearchParams('authDomain', authDomain);

        return url.pathname + url.search;

        function addSearchParams(type: string, value: any) {
            if (value)
                url.searchParams.set(type, value.toString());
        }
    }
    return {
        routes: {
            Home: () => `/${tenant.path}/home`,
            Documents: () => `/${tenant.path}/documents`,
            Uploader,
        },
    };
}