import LoadingButton, { LoadingButtonProps } from '@mui/lab/LoadingButton';
import { Box, Button, ButtonProps, Grid } from '@mui/material';
import { Delete, Save } from '@mui/icons-material';

type ButtonBarProps = {
    save?: LoadingButtonProps | boolean,
    delete?: LoadingButtonProps | boolean,
    cancel?: ButtonProps | boolean,
    startComponents?: (() => JSX.Element)[],
    endComponents?: (() => JSX.Element)[],
};

export function ButtonBar({ save, cancel, delete: delete_, startComponents, endComponents }: ButtonBarProps) {

    return (
        <Box mt={2} display="flex" justifyContent="space-between">
            <Grid container justifyContent="flex-start" gap={1}>
                {(startComponents || []).map((Component, i) => <Component key={i} />)}
            </Grid>
            <Grid container justifyContent="flex-end" gap={1}>
                {(endComponents || []).map((Component, i) => <Component key={i} />)}
                {cancel && <Button {...cancel as ButtonProps} color="primary" variant="outlined" >Cancel</Button>}
                {delete_ && <LoadingButton {...delete_ as LoadingButtonProps} color="error" variant="contained" loadingPosition="end" endIcon={<Delete />}> Delete </LoadingButton>}
                {save && <LoadingButton {...save as LoadingButtonProps} color="primary" variant="contained" loadingPosition="end" endIcon={<Save />}> Save </LoadingButton>}
            </Grid>
        </Box>
    );
}