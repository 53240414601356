import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Autocomplete from "components/Autocomplete"
import { useApi } from "hooks";
import { Metadata } from "types"
import Feedback, { FeedbackProps } from "components/@beca-common-react-legacy/Feedback";

type MetadataValueAutocompleteState = {
    options?: string[],
    feedback?: FeedbackProps,
    loading?: boolean
}

export type MetadataValueAutocompleteProps = {
    disabled?: boolean,
    metadata: Metadata,
    onChange?: (value: string) => void,
}

export default function MetadataValueAutocomplete({ disabled, metadata, onChange }: MetadataValueAutocompleteProps) {

    const [state, setState] = useState<MetadataValueAutocompleteState>({});

    const { getMetadataValues } = useApi();

    useEffect(resetState, [metadata.key]);
    useEffect(loadMetadataValues, [state.loading]);

    return (
        <>  
            <Box display={state.feedback ? 'block' : 'none'}>   
                <Feedback {...state.feedback} />
            </Box>
            <Autocomplete 
                disabled={disabled}
                value={metadata.value}
                newOptionText={metadata.key}
                options={state.options}
                loadOptions={loadOptions}
                onChange={onChange}
                isRequired={metadata.isRequired}
            />
        </>
    )

    function loadOptions() {
        if (state.options === undefined)
            setState({...state, loading: true});
    }

    function loadMetadataValues() {
        if (!state.loading)
            return;

        if (!metadata.key) {
            setState({ 
                options: [],
                feedback: { message: "A key needs to entered to retrieve options", severity: "warning", duration: 3000 } 
            });
            return;
        }
        
        getMetadataValues({
            input: { metadataKey: metadata.key },
            onSuccess: (options:string[]) => setState({ options }),
            onError: setError
        });

        function setError() {
            setState({ 
                options: [],
                feedback: { message: `Failed to obtain ${metadata.key} options`, severity: "error", neverHide: true } 
            });
        }
    }

    function resetState() {
        if (state.options !== undefined || state.feedback !== undefined || state.loading !== undefined)
            setState({});
    }
}