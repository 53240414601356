import { Box } from "@mui/material";
import { Error } from "utils";


export type ErrorContainerProps = {
    error?: string;
    children: JSX.Element;
    errorElement?: ({ error }: { error: string; }) => JSX.Element;
};

export function ErrorContainer({ error, children, errorElement: ErrorElement = Error }: ErrorContainerProps) {
    if (error) {
        return <Box m={1}>
            <ErrorElement error={error} />
        </Box>;
    }

    return children;
}
