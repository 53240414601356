import { useState } from "react";
import { LoaderContainer } from "utils";
import { Metadata } from "types";
import { useApi, useDeepState } from "hooks";
import { MultiFileEditForm } from "components/ui/MultiFileEditForm";
import Feedback, { FeedbackProps } from "components/@beca-common-react-legacy/Feedback";


export type MultiFileEditProps = {
    fileIds: string[],
    onSuccess?: () => void,
    onCancel?: () => void,
}

export function MultiFileEdit({ fileIds, onSuccess, onCancel }: MultiFileEditProps) {

    const api = useApi();

    const [feedback, setFeedback] = useState<FeedbackProps>();
    const [state, setState] = useState<{ error?: string; isSaving?: boolean; }>({});
    const [data, setData] = useDeepState<{ name?: string; metadatas?: Metadata[]; }>({});

    return (
        <>
            <Feedback {...feedback} />
            <LoaderContainer loaded={data} error={state.error}>
                <MultiFileEditForm data={data} onChange={setData} onSave={onSave} isSaving={state.isSaving} onCancel={onCancel} />
            </LoaderContainer>
        </>
    );

    function onSave() {
        setState({ ...state, isSaving: true });
        api.postMetadatas({
            input: {
                fileIds,
                metadatas: data.metadatas,
                startFileProcessing: false
            },
            onSuccess,
            onError: (error) => {
                setFeedback({ message: error, severity: "error", neverHide: true })
                setState({ ...state, isSaving: false });
            }
        });
    }
}
