import { ToggleButton, ToggleButtonProps, Tooltip } from "@mui/material";
import { useIntl } from "react-intl";


export type TooltipToggleButtonProps = {
    children: React.ReactNode,
    value: string,
    groupId: string,
} & ToggleButtonProps

export type TooltipToggleButtonVisualProps = {
    children: React.ReactNode,
    value: string,
    tooltip: string
} & ToggleButtonProps

export function TooltipToggleButtonVisual({ children, tooltip, ...buttonProps }: TooltipToggleButtonVisualProps) {
    return (
        <Tooltip title={tooltip}>
            <ToggleButton {...buttonProps}>
                {children}
            </ToggleButton>
        </Tooltip>
    );
}

export default function TooltipToggleButton({ children, groupId, value, ...buttonProps }: TooltipToggleButtonProps) {
    const intl = useIntl();
    const tooltip = intl.formatMessage({
        id: `toggleButton.${groupId}.${value}.tooltip`
    });

    return <TooltipToggleButtonVisual value={value} tooltip={tooltip} {...buttonProps}> {children} </TooltipToggleButtonVisual>;
}
