import { Table, TableBody, TableCell, TableContainer, TableRow } from "@mui/material";
import { useContext, useMemo } from "react";
import { MetadataKeysContext } from "context";
import { Metadata } from "types"
import ActionCell from "./ActionCell"
import EmptyMessage from "./EmptyMessage"
import MetadataKeyField from "./MetadataKeyField"
import MetadataValueField from "./MetadataValueField"
import NewRow from "./NewRow"

export type MetadataTableProps = {
    disabled?: boolean,
    metadatas: Metadata[],
    onChange: (metadatas: Metadata[]) => void,
    allowNew?: boolean,
    allowDelete?: boolean,
    allowEditKeys?: boolean,
    allowEditValues?: boolean,
    displaySystemMetadata?: boolean,
    emptyMessage?: string,
}

export function MetadataTable({
    metadatas = [],
    disabled,
    onChange,
    allowEditKeys = true,
    allowEditValues = true,
    allowNew = true,
    allowDelete = true,
    displaySystemMetadata = false,
    emptyMessage = "No metadata",
}: MetadataTableProps) {

    const metadataKeysContext = useContext(MetadataKeysContext);
    const metadataKeys = metadataKeysContext.loading ? null : metadataKeysContext.metadataKeys

    const metadataDict = useMemo(() =>
        Object.values(displaySystemMetadata
            ? metadatas
            : metadatas.filter(x => !x.system)
        ), [metadatas, displaySystemMetadata]);

    return (
        <>
            <EmptyMessage hidden={metadatas.length > 0} message={emptyMessage} />
            <TableContainer>
                <Table size="small">
                    <NewRow disabled={disabled} hidden={!allowNew} onAdd={onAddMetadata} />
                    <TableBody>
                        {metadataDict.map((metadata, i) => (
                            <TableRow key={i}>
                                <TableCell width="40%" sx={{ verticalAlign: "top" }}>
                                    <MetadataKeyField allowEdit={allowEditKeys} disabled={disabled} metadata={metadata} values={metadataKeys} onChange={updateMetadatasKey} />
                                </TableCell>
                                <TableCell width="60%" align="right" sx={{ verticalAlign: "top" }}>
                                    <MetadataValueField allowEdit={allowEditValues} metadata={metadata} disabled={disabled} onChange={updateMetadatasValue} />
                                </TableCell>
                                <ActionCell disabled={disabled} isEditable={isEditable(metadata)} index={i} onAction={onDeleteMetadata} />
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );

    function updateMetadatasKey(metadata: Metadata, key?: string) {
        const index = metadatas.indexOf(metadata);
        metadatas[index].key = key;
        onChange([...metadatas]);
    }

    function updateMetadatasValue(metadata: Metadata, value?: string) {
        const index = metadatas.indexOf(metadata);
        metadatas[index].value = value;
        onChange([...metadatas]);
    }

    function onDeleteMetadata(index: number) {
        metadatas.splice(index, 1);
        onChange([...metadatas]);
    }

    function onAddMetadata() {
        onChange([...metadatas, { key: "Tag", isNew: true }]);
    }

    function isEditable(metadata: Metadata): boolean {
        if (metadata.system)
            return false;

        if (metadata.isRequired)
            return false;

        if (metadata.isNew)
            return true;

        return allowDelete;
    }
}

