import { TextField, Tooltip } from "@mui/material";
import { makeStyles } from 'tss-react/mui';
import { Info } from "@mui/icons-material"

const useStyles = makeStyles()(() => ({
    root: {
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                border: 'none',
            },
        },
    },
}));

export interface NonEditableFieldProps {
    tooltip?: string,
    value: string,
}

export default function NonEditableField({ value, tooltip = "" }: NonEditableFieldProps) {

    const { classes } = useStyles();

    return <TextField
        fullWidth
        variant="outlined"
        className={classes.root}
        InputProps={{
            readOnly: true,
            endAdornment: <FieldTooltip />
        }}
        size="small"
        value={value} />

    function FieldTooltip() {
        if (!tooltip) return null;

        return (
            <Tooltip title={tooltip} placement="top">
                <Info style={{ fontSize: 16 }} />
            </Tooltip>
        );
    }
}