import { createContext } from "react";
import { Tenant } from "types";

interface TenantContextState {
    tenant: Tenant
}

const contextDefaultValues: TenantContextState = {
    tenant: null,
}; 

export const TenantContext = createContext<TenantContextState>(
    contextDefaultValues
);

type TenantProviderProps = {
    children: JSX.Element
    tenantPath: string
}

export function TenantProvider({children, tenantPath}: TenantProviderProps) {

    const tenant: Tenant = { path: tenantPath };

    return (
        <TenantContext.Provider
            value={{
                tenant: tenant
            }}
        >
            {children}
        </TenantContext.Provider>
    );
};
export default TenantProvider;