import { useEffect } from "react";
import { useLocalStorage } from "hooks";
import { LocalStorageKey } from "hooks/useLocalStorage";
import { Dictionary, File } from "types"

export function useCreatedFiles({ onNewFiles }: { onNewFiles?: (newFiles: Dictionary<File>) => void }) {

    //todo replace with SignalR: #89356 
    const { value: newFilesEvent } = useLocalStorage(LocalStorageKey.newFileEvent);

    useEffect(() => {
        if (newFilesEvent.firstLoad) return;
        onNewFiles(JSON.parse(newFilesEvent.value));
    }, [newFilesEvent]);
}
