import { EventType } from "enums/EventType";
import { createContext, useEffect, useState } from "react";

export type SelectState = {
    tags?: string[]
}

type BusState = {
    select: SelectState
    doSelect: (payload: SelectState) => void
}
const defaultBusState: BusState = {
    select: {},
    doSelect: () => { }
}
export const BusContext = createContext<BusState>(defaultBusState);

type BusProviderProps = {
    children: JSX.Element,
    selectOverride?: SelectState
}

export function BusProvider({ children, selectOverride }: BusProviderProps) {
    const [select, setSelect] = useState<SelectState>({});
    useEffect(() => {
        if (selectOverride) {
            setSelect(selectOverride);
            return;
        }
        window.addEventListener("message", onMessage);
        window.parent.postMessage({
            eventType: EventType.ReloadVisual,
        }, "*");
        return () => {
            window.removeEventListener("message", onMessage)
        }
            ;
    }, [selectOverride]);

    return (
        <BusContext.Provider value={{
            select: select,
            doSelect: updateSelection
        }}>
            {children}
        </BusContext.Provider>
    );

    //TODO: refactor this in a shared hook
    function onMessage(e: any) {
        const type: EventType = e?.data?.eventType;
        const payload = e?.data?.payload;
        switch (type) {
            case (EventType.Selection): {
                if (payload)
                    setSelect(payload);
                break;
            }
        }
    }

    //TODO: use the iframeCommunication hook
    function updateSelection(payload: SelectState) {
        setSelect(payload);
        window.parent.postMessage({
            eventType: EventType.Selection,
            payload
        }, "*");
    }
}

export default BusProvider;