import { useContext, useEffect, useMemo, useState } from 'react'
import useSpaTenantRoute from "hooks/useApi/useSpaTenantRoute";
import { ConfigContext } from 'context';
import { MetadataDto as Metadata } from 'types';
import { isIframed } from 'utils/IframeUtil';

export function useUploaderUrl() {

    const { getContextualMetadata } = useContext(ConfigContext).config.upload;

    const [error, setError] = useState<string>();

    const [contextualMetadata, setContextualMetadata] = useState<Metadata[]>([]);

    const { routes } = useSpaTenantRoute();

    const url = useMemo(() => {
        if (isIframed()) {
                return contextualMetadata.length? routes.Uploader({ metadata: contextualMetadata }) : undefined
        } else {
            return routes.Uploader({});
        }

    }, [contextualMetadata]);

    useEffect(() => {
        getContextualMetadata({ onSuccess: setContextualMetadata, onError: setError })

    }, []);

    return { url, error };
}
