import { Alert, Grid, Typography, AlertTitle } from "@mui/material";
import { Info } from "@mui/icons-material";
import { ButtonBar } from "./ButtonBar";


export type FileDeleteFormProps = {
    deletableFiles?: { id: string, name: string }[],
    undeletableFiles?: { id: string, name: string }[],
    isDeleting: boolean,
    onDelete?: () => void,
    onCancel?: () => void,
}

export function FileDeleteForm({ deletableFiles, undeletableFiles, isDeleting, onDelete, onCancel }: FileDeleteFormProps) {

    if (!deletableFiles || deletableFiles.length === 0)
        return <Alert severity="warning">None of the selected files are allowed to be deleted</Alert>;

    return (
        <>
            <Grid container direction="column" alignItems='center' spacing={2}>
                <Grid container item direction="column" justifyContent='center' alignItems='center' minHeight={270} display="flex" gap={1}>
                    <Typography variant="h4">Are you sure?</Typography>
                    <DeletableFileMessage />
                    <UndeletableFileMessage />
                    <Grid item display="flex" gap={1}>
                        <Info />
                        <Typography>Items in the recycle bin will be permanently deleted after 30 days</Typography>
                    </Grid>
                </Grid>
            </Grid>
            <ButtonBar
                delete={onDelete && {
                    onClick: onDelete,
                    loading: isDeleting,
                }}
                cancel={onCancel && {
                    onClick: onCancel,
                    disabled: isDeleting
                }} />
        </>
    );

    function DeletableFileMessage() {
        if (deletableFiles?.length > 1)
            return <>
                <Typography>Do you want to move {deletableFiles.length} files to the recycle bin? </Typography>
            </>;

        return <span>
            <Typography component="span">Do you want to move </Typography>
            <Typography component="span" sx={{ textDecoration: "underline" }}>{deletableFiles[0].name}</Typography>
            <Typography component="span"> to the recycle bin? </Typography>
        </span>;

    }

    function UndeletableFileMessage() {

        if (undeletableFiles == null || undeletableFiles.length == 0)
            return null;

        return (
            <Alert severity="warning">
                <AlertTitle>Some files are not allowed to be deleted and will be ignored</AlertTitle>
                <ul>
                    {undeletableFiles.map((x, i) => <li key={i}>{x.name}</li>)}
                </ul>

            </Alert>
        );

    }
}
