import { useState } from "react";
import queue from "queue";
import useApi from "./useApi";
import { NewFileDto } from "types";
import axios from "axios";

export type UploadFileCallbacks = {
    onStart?: (file: File) => void,
    onProgress?: (file: File, percent: number) => void,
    onComplete?: (file: File) => void,
    onError?: (file: File, error: string) => void,
}

export default function useFileUploader(concurrency = 5) {

    const [uploadQueue,] = useState(queue({ concurrency, autostart: true }));

    const api = useApi();

    return { queueUploadFile };

    async function queueUploadFile(file: File, { onStart = () => { }, onProgress = () => { }, onComplete = () => { }, onError = () => { } }: UploadFileCallbacks): Promise<string> {

        return new Promise(resolve =>
            api.createFile({
                input: { fileName: file.name },
                onSuccess: async (newFileDto: NewFileDto) => {
                    resolve(newFileDto.id)
                    await addToQueue(newFileDto, file, { onStart, onProgress, onComplete, onError });
                },
                onError: (error) => onError(file, error)
            })
        );
    }

    async function addToQueue(fileDto: NewFileDto, file: File, { onStart = () => { }, onProgress = () => { }, onComplete = () => { }, onError = () => { } }: UploadFileCallbacks) {

        if (!file || !fileDto)
            onError(file, "No file provided");

        uploadQueue.push(async () => {

            onStart(file);

            axios.request({
                method: "PUT",
                url: fileDto.sasUri,
                data: file,
                headers: {
                    "Content-type": file.type,
                    "x-ms-blob-type": "BlockBlob"
                },
                onUploadProgress: (progress) => {
                    const percent = progress.loaded * 100 / file.size;
                    onProgress(file, percent);
                }
            })
                .catch(error => {
                    api.uploadFailed({
                        input: { fileId: fileDto.id },
                        onSuccess: () => onError(file, error),
                        onError: (error) => onError(file, error)
                    })
                    return;
                })
                .then(() =>
                    api.uploadCompleted({
                        input: { fileId: fileDto.id },
                        onSuccess: () => onComplete(file),
                        onError: (error) => onError(file, error)
                    }));
        });

        return fileDto.id;
    };


}