import Button from "@mui/material/Button";
import { ArrowBack, Delete } from '@mui/icons-material';
import TooltipButton from "components/Tooltip/TooltipButton";

export function RecycleBinButton({ showDeleted, onChange }: { showDeleted: boolean, onChange: (showDeleted: boolean) => void }) {

    if (showDeleted)
        return <TooltipButton buttonId="goBackToFiles" size="small" variant="outlined" startIcon={<ArrowBack />} onClick={() => onChange(false)} />

    else
        return <TooltipButton buttonId="goToRecycleBin" size="small" variant="outlined" endIcon={<Delete />} onClick={() => onChange(true)} />

}
