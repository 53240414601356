import { useMemo } from "react";
import { Stack } from "@mui/material";
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { useKeyEvent } from "hooks";
import TooltipIconButton from "components/Tooltip/TooltipIconButton";
import { isEmpty, last, first } from "lodash";

export type SimpleCarouselProps<TItem extends { id: string }> = {
    items: TItem[],
    item?: TItem,
    itemComponent: (item: TItem) => JSX.Element,
    onNext?: () => void;
    onPrevious?: () => void;
};
export function SimpleCarousel<TItem extends { id: string }>({ items, item, itemComponent, onNext, onPrevious }: SimpleCarouselProps<TItem>) {

    useKeyEvent({
        keyEvents: [
            {
                key: "ArrowLeft",
                onKeydown: onPreviousClick
            },
            {
                key: "ArrowRight",
                onKeydown: onNextClick
            }
        ]
    });

    const isItemsEmpty = isEmpty(items);
    const isPrevisouEventDisabled = isItemsEmpty || first(items)?.id === item.id;;
    const IsNextEventDisabled = isItemsEmpty || last(items)?.id === item.id;;

    return (
        <Stack height="100%" direction="row" gap={1} alignItems="center" justifyContent="space-around" pl={1} pr={1}>
            <TooltipIconButton Icon={ChevronLeft} buttonId="carousel.previous" onClick={onPreviousClick} disabled={isPrevisouEventDisabled} id="carousel-onPrevious" />
            {item && itemComponent(item)}
            < TooltipIconButton Icon={ChevronRight} buttonId="carousel.next" onClick={onNextClick} disabled={IsNextEventDisabled} id="carousel-onNext" />
        </Stack>
    );

    function onPreviousClick() {
        if (isPrevisouEventDisabled) return;
        if (!onPrevious)
            console.warn("No 'onPrevious' callback defined");
        else
            onPrevious();
    }

    function onNextClick() {
        if (IsNextEventDisabled) return;

        if (!onPrevious)
            console.warn("No 'onNext' callback defined");
        else
            onNext();
    }

}
