import { Box } from "@mui/material";
import { Error, Loading } from "utils";
import { ErrorContainer } from "./ErrorContainer";

export type LoaderContainerProps = {
    loaded?: boolean | object;
    error?: string;
    children: JSX.Element;
    errorElement?: ({ error }: { error: string }) => JSX.Element,
    loadingElement?: () => JSX.Element
};

export function LoaderContainer(props: LoaderContainerProps) {

    const { loaded, error, children, errorElement = Error, loadingElement: LoadingElement = Loading } = props;

    if (error) {
        const ErrorElement = errorElement
        return <Box m={1}>
            <ErrorElement error={error} />
        </Box>;
    }

    return <ErrorContainer {...props}>
        {loaded ? children : <LoadingElement />}
    </ErrorContainer>
}


