import { createTheme, ThemeOptions } from '@mui/material/styles';
import { common } from '@mui/material/colors';

const baseThemeOptions: ThemeOptions = {
    mixins: {
        toolbar: {
            height: 52,
        }
    },
    typography: {
        fontFamily: '"Segoe UI", "Helvetica Neue"',
        h1: {
            fontWeight: 600,
            fontSize: '1.1em',
            color: 'rgb(89,89,89)',
            textTransform: 'uppercase'
        },
        h2: {
            fontWeight: 600,
            fontSize: '1em',
            color: 'rgb(128,128,128)',
            textTransform: 'uppercase'
        },
        h3: {
            fontWeight: 400,
            fontSize: '1em',
            color: 'rgb(128,128,128)',
            textTransform: 'uppercase'
        },
    }
};

const becaTeal = 'rgb(18, 168, 178)';
const becaYellow = 'rgb(255, 206, 0)'

const defaultThemeOption: ThemeOptions = {
    ...baseThemeOptions,
    palette: {
        primary: {
            main: becaTeal,
            contrastText: common.white,
        },
        secondary: {
            main: becaYellow,
        },
        background: {
            default: 'rgb(245, 245, 245)'
        }
    },
};

const beyonLightPrimary = '#8c0e84';
const beyonLightSecondary = '#127d81';
const beyonDarkPrimary = '#b85daa';
const beyonDarkSecondary = '#12agb1';

const beyonLightThemeOptions: ThemeOptions = {
    ...baseThemeOptions,
    palette: {
        primary: {
            main: beyonLightPrimary,
            contrastText: common.white,
        },
        secondary: {
            main: beyonLightSecondary,
        },
        background: {
            default: 'rgb(245, 245, 245)'
        }
    },
}

const beyonDarkThemeOptions: ThemeOptions = {
    ...baseThemeOptions,
    palette: {
        mode: 'dark',
        primary: {
            main: beyonDarkPrimary,
        },
        secondary: {
            main: beyonDarkSecondary,
        },
        contrastThreshold: 4.5
    },
}

export const defaultTheme = createTheme(defaultThemeOption);
export const beyonLightTheme = createTheme(beyonLightThemeOptions);
export const beyonDarkTheme = createTheme(beyonDarkThemeOptions);