import Autocomplete from "components/Autocomplete"
import NonEditableField from "components/NonEditableField"
import { Metadata } from "types"

export interface MetadataKeyFieldProps {
    allowEdit: boolean,
    disabled?: boolean,
    metadata: Metadata,
    values: string[],
    onChange: (metadata: Metadata, value: string) => void
}

export default function MetadataKeyField({ allowEdit, disabled, metadata, values, onChange }: MetadataKeyFieldProps) {
    const isNotEditable = metadata.system || metadata.isRequired || !(allowEdit || metadata.isNew);
    if (isNotEditable)
        return <NonEditableField value={metadata.key} />;

    return (
        <Autocomplete
            disabled={disabled}
            value={metadata.key}
            newOptionText={'option'}
            options={values}
            loadOptions={() => { }}
            onChange={(option: string) => onChange(metadata, option?.slice(0, 80))}
        />
    )
}