import { EventType } from "enums/EventType";

type SendEventParams<TMessage, TOutput> = {
    eventType: EventType;
    message?: TMessage;
    onSuccess?: (data: TOutput) => void;
    onError?: (error: string) => void;
}

type ParentEvent<TPayload> = {
    eventType: EventType;
    payload: TPayload;
}

export const useIframeCommunication = () => {
    function sendEvent<TMessage, TOutput>({ eventType, message, onSuccess, onError }: SendEventParams<TMessage, TOutput>) {
        const messageHandler = (event: MessageEvent<ParentEvent<TOutput>>) => {
            if (event.data.eventType === eventType) {
                onSuccess(event.data.payload);
                window.removeEventListener("message", messageHandler);
            }
        };

        if (onSuccess) {
            window.addEventListener("message", messageHandler);
        }

        window.parent.postMessage(
            {
                ...message, //keep for backward compatibility
                message,
                eventType
            },
            // This enables all parents
            "*");
    }

    return { sendEvent }
}
