import useFetchHook, { FetchParams } from "components/@beca-common-react-legacy/useFetchHook";
import { TenantContext } from "context";
import { useIdentity } from "hooks";
import { useContext } from "react";

interface ApiFetchParams extends FetchParams {
    path: string
}

function useApiFetch() {

    const { useFetch: fetch } = useFetchHook();

    const { getIdentity } = useIdentity();

    const tenant = useContext(TenantContext).tenant;

    return { apiGet, apiPost, apiPatch, apiDelete };

    function apiGet(props: ApiFetchParams) {
        apiQuery(props)
    }

    function apiPost(props: ApiFetchParams) {

        apiQuery({
            ...props,
            method: 'POST',
        })
    }

    function apiPatch(props: ApiFetchParams) {

        apiQuery({
            ...props,
            method: 'PATCH',
        })
    }

    function apiDelete(props: ApiFetchParams) {

        apiQuery({
            ...props,
            method: 'DELETE',
        })
    }

    function apiQuery(props: ApiFetchParams) {

        getIdentity()
            .then((identity) => query(identity?.token))
            .catch((error) => props.onError({ message: error }));

        function query(token: string) {
            fetch({
                ...props,
                // TODO: Refactor so that environment variable is not required.
                // Currently will default to the environment variable if Assets is not Iframed
                url: `${(window as any).REACT_APP_API_ENDPOINT}/${tenant.path}/${props.path}`,
                bearer: token,
            });
        }
    }
}

export default useApiFetch;