

/*eslint unicode-bom: ["error", "always"]*/
//Auto generated scripts from 'Beca.Content.Typescript' using 'Typewriter'




export enum FileStatus {
        
    Pending = "Pending",    
    Uploading = "Uploading",    
    UploadFailed = "UploadFailed",    
    Processing = "Processing",    
    ProcessingError = "ProcessingError",    
    Completed = "Completed",    
    Uploaded = "Uploaded"
}
