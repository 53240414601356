import { Stack } from "@mui/material";

export type Controls = {
    control: JSX.Element,
    position: "start" | "middle" | "end";
}[];

export type ControlledBarProps = {
    className?: string;
    height?: string;
    width?: string;
    controls: Controls;
}

export function ControlledBar(props: ControlledBarProps) {
    const { className, height, width, controls } = props;

    return (
        <Stack direction="row" alignItems="center" justifyContent="space-between" className={className} height={height} width={width}>
            <Stack direction="row" alignItems="center" mx={1} spacing={1}>
                {controls.filter(control => control.position === "start").map((x, i) => <div key={i}>{x.control}</div>)}
            </Stack>
            <Stack direction="row" alignItems="center" mx={1} spacing={1}>
                {controls.filter(control => control.position === "middle").map((x, i) => <div key={i}>{x.control}</div>)}
            </Stack>
            <Stack direction="row" alignItems="center" mx={1} spacing={1}>
                {controls.filter(control => control.position === "end").map((x, i) => <div key={i}>{x.control}</div>)}
            </Stack>
        </Stack>
    );
}