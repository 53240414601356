import { Box, Typography } from "@mui/material";

export type EmptyMessageProps = {
    hidden: boolean,
    message: string
}

export default function EmptyMessage({hidden, message}: EmptyMessageProps) {
    if (hidden) return null;

    return (
        <Box pl={6}>
            <Typography variant="caption">{message}</Typography>
        </Box>
    );
}