import { Column, Config, Dictionary, FileFilterDto as Filter, FileSortDto as Sort, FileType, MetadataDto, Metadata } from "types";
import { createContext, useState } from "react";
import { useConfigApi, useIframeCommunication } from "hooks";
import { EventType } from "enums/EventType";
import { GetterParams } from "types/Config";
import { isIframed } from "utils";

interface ConfigContextState {
    config: Config
}

const contextDefaultValues: ConfigContextState = {
    config: null,
};

export const ConfigContext = createContext<ConfigContextState>(
    contextDefaultValues
);

const contextualMetadataTimeoutMs = 5000;

const defaultDocumentColumns = [
    { key: "Name", name: "File Name" },
    { key: "Status", name: "Status" }
];

interface ConfigProviderProps {
    children: JSX.Element,
    params?: string
    collectionKeysOverride?: string[];
    documentColumns?: Column[];
}

export function ConfigProvider({ children, params = window.location.search, collectionKeysOverride = null, documentColumns = defaultDocumentColumns }: ConfigProviderProps) {

    const [contextualMetadata, setContextualMetadata] = useState<Metadata[]>();

    const { sendEvent } = useIframeCommunication();
    const urlParams = new URLSearchParams(params);

    const concurrency = getIntConfig('concurrency', 5);;
    const maxFileSize = getIntConfig('maxFileSize', 50000000); // 50MB
    const filesLimit = getIntConfig('filesLimit', 50);
    const metadataFromUrl = getJsonConfig<MetadataDto[]>('metadata', []);
    const inputFields = getJsonConfig('inputFields', []);

    const allowUpload = getBoolConfig('allowUpload', true);
    const allowPreview = getBoolConfig('allowPreview', false);
    const allowDelete = getBoolConfig('allowDelete', true);

    const fileType = getFileTypeConfig("fileType");

    const columns = getJsonConfig('columns', documentColumns);
    const filters = getJsonConfig<Filter[]>('filters', []);
    const sorts = getJsonConfig<Sort[]>('sorts', []);
    const pageSize = getJsonConfig('pageSize', 25);

    const { data } = useConfigApi();

    const config: Config = {
        documents: {
            allowDelete,
            allowPreview,
            allowUpload,
            columns,
            filters,
            sorts,
            pageSize,
            fileType
        },
        upload: {
            concurrency,
            filesLimit,
            fileType,
            getContextualMetadata,
            inputFields,
            maxFileSize
        },
        collection: {
            keys: collectionKeysOverride ?? data?.collectionKeys
        },
        featureFlags: {
            beyonTheme: data?.featureFlags?.["BeyonTheme"]
        }
    }

    if (!data)
        return null;

    return (
        <ConfigContext.Provider value={{ config: config }} >
            {children}
        </ConfigContext.Provider>
    );


    function getContextualMetadata({ onSuccess, onError }: GetterParams<MetadataDto[]>): void {
        if (!isIframed()) {
            onSuccess(metadataFromUrl);
            return;
        }

        if (contextualMetadata) {
            onSuccess([...contextualMetadata, ...metadataFromUrl]);
            return;
        }
        const errorTimer = setTimeout(() => onError("Timeout getting contextual metadata"), contextualMetadataTimeoutMs);

        const onEventSuccess = (payload: Dictionary<string>) => {
            clearTimeout(errorTimer);
            const metadataFromParent: MetadataDto[] = Object.entries(payload).map(([key, value]) => ({ key, value, system: true, immutable: false }));

            setContextualMetadata(metadataFromParent);
            onSuccess([...metadataFromParent, ...metadataFromUrl]);
        }

        sendEvent<null, Dictionary<string>>({ eventType: EventType.GetContext, onSuccess: onEventSuccess, onError });
    }

    function getBoolConfig(configName: string, defaultValue: boolean = false) {
        const configValue = urlParams.get(configName);
        return configValue ? configValue === "true" : defaultValue;
    }

    function getIntConfig(configName: string, defaultValue: number = 0) {
        const configValue = urlParams.get(configName);
        return configValue ? parseInt(configValue) : defaultValue;
    }

    function getJsonConfig<TValue>(configName: string, defaultValue: TValue = {} as TValue): TValue {
        const configValue = urlParams.get(configName);

        if (configValue == null)
            return defaultValue;

        const value = JSON.parse(configValue);

        if (value == null)
            return defaultValue;

        if (Array.isArray(value) && value.length == 0)
            return defaultValue;

        if (Object.keys(value).length == 0)
            return defaultValue

        return value;
    }



    function getFileTypeConfig(configName: string, defaultValue: FileType = null) {
        var configValue = urlParams.get(configName);
        return FileType[configValue as keyof typeof FileType] ?? defaultValue;
    }
};

export default ConfigProvider;
