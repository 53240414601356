import { Link, LinkProps, Tooltip } from "@mui/material";
import { useIntl } from "react-intl";


export type TooltipLinkProps = {
    value: string,
    linkId: string,
} & LinkProps

export type TooltipLinkVisualProps = {
    value: string,
    tooltip: string
} & LinkProps

export function TooltipLinkVisual({ value, tooltip, ...linkProps }: TooltipLinkVisualProps) {
    return (
        <Tooltip title={tooltip}>
            <Link {...linkProps}>
                {value}
            </Link>
        </Tooltip>
    );
}

export default function TooltipLink({ value, linkId, ...linkProps }: TooltipLinkProps) {
    const intl = useIntl();
    const tooltip = intl.formatMessage({
        id: `link.${linkId}.tooltip`
    }, { filename: value });

    return <TooltipLinkVisual tooltip={tooltip} value={value} {...linkProps} />;
}
