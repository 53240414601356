import React, { useEffect, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';
import { SnackbarCloseReason } from '@mui/material/Snackbar';
import CloseIcon from '@mui/icons-material/Close';
import { SyntheticEvent } from 'react';
import MuiAlert from '@mui/material/Alert';

/**
 * DO NOT ALTER: This component is extracted from the legacy @beca/common-react
 * Technical Debt item: #86766
 */

export interface FeedbackProps {
    message?: string,
    severity?: "error" | "warning" | "info" | "success",
    duration?: number,
    neverHide?: boolean,
    allowDismiss?: boolean
}

export default function Feedback(props: FeedbackProps) {

    const { message, severity, duration, neverHide, allowDismiss } = props;

    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (message && !open) {
            setOpen(true);
        }
    }, [props]);

    const autoHideDuration = neverHide 
        ? null // autohide disables when null
        : duration || 1000; // 10s
 
    return (
        <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            open={open}
            autoHideDuration={autoHideDuration}
            onClose={onSnackClose}
        >
            <div /*this div fixes "Cannot read property scrollTop of null" error*/>
                <Alert severity={severity}>
                    {message}
                    {(neverHide == false || allowDismiss) &&
                        <IconButton size="small" aria-label="close" color="inherit" onClick={onButtonClose}>
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    }
                </Alert>
            </div>
        </Snackbar>
    );

    function onSnackClose(event: Event | SyntheticEvent<any, Event>, reason: SnackbarCloseReason) {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    function onButtonClose(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        setOpen(false);
    };
}

function Alert(props: any) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}