import { createContext, useEffect, useState } from "react";
import { useApi } from "hooks";
import { MetadataDto } from "types";

interface MetadataKeysContextState {
    error: boolean,
    loading: boolean,
    metadataKeys: string[],
    refetch: () => void
}

const contextDefaultValues: MetadataKeysContextState = {
    error: false,
    loading: true,
    metadataKeys: [],
    refetch: () => { }
};

export const MetadataKeysContext = createContext<MetadataKeysContextState>(
    contextDefaultValues
);

type MetadataKeysProviderProps = { children: JSX.Element }

export function MetadataKeysProvider({ children }: MetadataKeysProviderProps) {

    const [context, setContext] = useState<MetadataKeysContextState>(contextDefaultValues);
    const api = useApi();

    const refetch = () => {
        api.getMetadataKeys({
            onSuccess: (metadataKeys: MetadataDto[]) => {
                const nonSystemMetadataKeys = metadataKeys.filter(metadata => !metadata.system).map(metadata => metadata.key);
                setContext({
                    error: false,
                    loading: false,
                    metadataKeys: nonSystemMetadataKeys,
                    refetch: refetch
                })
            },
            onError: () => {
                setContext({
                    error: true,
                    loading: false,
                    metadataKeys: [],
                    refetch: refetch
                });
            }
        })
    }

    useEffect(() => {
        refetch();
    }, []);

    return (
        <MetadataKeysContext.Provider
            value={context}
        >
            {children}
        </MetadataKeysContext.Provider>
    );
};
export default MetadataKeysProvider;
