
import { File } from "types";

//https://stackoverflow.com/questions/15900485/correct-way-to-convert-size-in-bytes-to-kb-mb-gb-in-javascript
export function formatBytes(bytes: number, decimals = 2, k = 1024) {
    if (bytes === 0) return '0 Bytes';

    //const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export function isDeletable(file: File): boolean {
    return !file.name.toLocaleLowerCase().endsWith("slpk");
}

export function formatFileType(fileType: string) {
    switch (fileType) {
        case "Pdf":
            return "PDF";
        case "PointCloud":
            return "Point Cloud";
        default:
            return fileType;
    }
}