import { Alert, Box } from "@mui/material";

export default function Error({ error }: { error: string }) {

    if (!error) return null;

    return (
        <Box width="100%">
            <Alert severity="error">An unexpected error occured: {error}</Alert>
        </Box>
    );
}
