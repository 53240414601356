import { Fullscreen } from "@mui/icons-material";
import TooltipIconButton from "components/Tooltip/TooltipIconButton";

export function ViewInFullscreenButton({ show, onClick }: { show: boolean; onClick: () => void; }) {

    if (!show)
        return null;

    return (
        <TooltipIconButton Icon={Fullscreen} buttonId="enterFullscreen" onClick={onClick} />
    );
}
