import { Button, ButtonProps, Tooltip } from "@mui/material";
import { useIntl } from "react-intl";


export type TooltipButtonProps = {
    buttonId: string,
} & ButtonProps

export type TooltipButtonVisualProps = {
    id: string,
    label: string,
    tooltip: string
} & ButtonProps

export function TooltipButtonVisual({ label, tooltip, id, ...buttonProps }: TooltipButtonVisualProps) {

    if (buttonProps.disabled)
        return <Button id={id} {...buttonProps}>
            {label}
        </Button>

    return (
        <Tooltip title={tooltip}>
            <Button id={id} {...buttonProps}>
                {label}
            </Button>
        </Tooltip>
    );
}

export default function TooltipButton({ buttonId, ...buttonProps }: TooltipButtonProps) {
    const intl = useIntl();
    const tooltip = intl.formatMessage({
        id: `button.${buttonId}.tooltip`,
    });
    const label = intl.formatMessage({
        id: `button.${buttonId}.label`,
    });

    return <TooltipButtonVisual id={`button.${buttonId}`} tooltip={tooltip} label={label} {...buttonProps} />;
}
