import { useMemo } from "react";
import jwt_decode, { JwtPayload } from "jwt-decode";
import { Identity } from "hooksuseIdentity";

export function useApiIdentity() {

    const authDomain = useMemo(getAuthDomain, []);

    return { getIdentity };

    function getIdentity() {

        return new Promise<Identity>((resolve, reject) => {

            const tokenRequestConfig: RequestInit = { method: 'post', mode: 'cors', credentials: 'include' };

            if (!authDomain) {
                reject("No valid auth domain found}");
            }

            fetch(`${authDomain}/identity/token`, tokenRequestConfig)
                .then(response => response.json()
                    .then(token => {

                        if (!token)
                            reject("User authentication failed. No token returned");

                        const decoded = jwt_decode<JwtPayload>(token);
                        resolve({ token, decoded });
                    }))
                .catch(() => reject("User authentication failed. No token returned"));
        });
    }
}

export function getAuthDomain() {

    const searchParams = new URLSearchParams(window.location.search);
    if (searchParams.has("authDomain"))
        return searchParams.get("authDomain");

    const hostDomain = document.location.ancestorOrigins[0];

    if (!hostDomain || hostDomain.includes("localhost")) {
        var envHostDomain = (window as any).REACT_APP_AUTH_DOMAIN;
        if (envHostDomain)
            return envHostDomain;

        console.error("No auth domain available");
        return null;
    }

    return process.env.NODE_ENV == 'production'
        ? hostDomain
        : hostDomain || (window as any).REACT_APP_AUTH_DOMAIN;
}
