import { useContext, useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import { FileLinksContext } from "context";
import { LoaderContainer } from "utils";
import { FileLinks, FileThumbnails } from "types";
import { FileView } from "./index";
import { Controls as ControlledBarControls } from "./ControlledBar";

export type FileViewLoaderProps = {
    fileId: string;
    fileView: FileThumbnails;
    allowZoomPanPinch?: boolean;
    fallbackMessage?: boolean;
    displayHeader?: boolean,
    cursor?: string;
    additionalControls?: ControlledBarControls;
    onClick?: () => void;
    onError?: (error: string) => void;
    onClose?: () => void;
};

export function FileViewLoader(props: FileViewLoaderProps) {

    const { fileId, onError } = props;
    const [links, setLinks] = useState<FileLinks>(null);
    const [error, setError] = useState<string>(null);

    const fileLinksContext = useContext(FileLinksContext);

    useEffect(() => {
        fileLinksContext.getFileLinks({
            fileIds: [fileId],
            onSuccess: (filesLinks) => filesLinks && setLinks(filesLinks[0]),
            onError: (error) => {
                setError(error);
                if (onError)
                    onError(error);
            }
        });
    }, [fileId]);

    if (!links)
        return <CircularProgress />;

    return (
        <LoaderContainer loaded={links} error={error} loadingElement={() => <CircularProgress />}>
            <FileView {...props} links={links} />
        </LoaderContainer>
    );
}