export enum EventType {
    GetContext = "DT_Get_Context",
    Selection = "DT_Selection",
    SetNavigation = "DT_Navigation",
    SetFilter = "DT_Filter",
    AddTwin = "DT_AddTwin",
    SetCollections = "CM_SetCollections",
    ReloadVisual = "DT_Reload_Visual",
    GetContentUrl = "CM_Get_Url",
    GET_IDENTITY_TOKEN = "DT_GET_IDENTITY_TOKEN",
    SET_IDENTITY_TOKEN = "DT_SET_IDENTITY_TOKEN",
    GET_THEME_MODE = "DT_GET_THEME_MODE",
    SET_THEME_MODE = "DT_SET_THEME_MODE",
}
