import { useState, useEffect } from "react";
import { localStorageUtility } from "utils/storage";

export enum LocalStorageKey {
    newFileEvent = "newFileEvent",
}

export type LocalStorageState = {
    value: string
    firstLoad: boolean
}

export function useLocalStorage(key: string) {

    const [state, setState] = useState<LocalStorageState>({ value: getValueFromStorage(), firstLoad: true });

    useEffect(() => {
        window.addEventListener('storage', onLocalStorageChange);

        return () => window.removeEventListener('storage', onLocalStorageChange);

        function onLocalStorageChange() {
            const storageValue = getValueFromStorage();
            if (storageValue !== state.value)
                setState({ value: storageValue, firstLoad: false });
        }
    }, [])

    return {
        value: state,
        setValue: (v: string) => localStorageUtility.setItem(key, v)
    };

    function getValueFromStorage(): string {
        return localStorageUtility.getItem(key);
    }
};