import { FileLinksContext } from "context/fileLinksContext";
import FileSaver from "file-saver";
import { useContext } from "react";

type DownloadFilesProps = {
    fileIds: string[],
    onError?: (error: string) => void
}

export default function useDownloadFile() {

    const fileLinksContext = useContext(FileLinksContext);

    return { downloadFiles };

    function downloadFiles({ fileIds, onError }: DownloadFilesProps) {

        fileLinksContext.getFileLinks({
            fileIds: fileIds,
            onSuccess: (filesLinks) => filesLinks.forEach(fileLinks => FileSaver.saveAs(fileLinks.link, fileLinks.name)),
            onError
        })
    }
}
