import { IconButton, TableCell } from "@mui/material";
import { Delete } from "@mui/icons-material"

export type ActionCellProps = {
    disabled: boolean,
    isEditable: boolean,
    index: number,
    onAction: (index: number) => void
}

export default function ActionCell({ isEditable, disabled, index, onAction }: ActionCellProps) {

    return (
        <TableCell width={5} sx={{ verticalAlign: "top" }}>
            {isEditable &&
                <IconButton onClick={() => onAction(index)} disabled={disabled}>
                    <Delete color="error" />
                </IconButton>
            }
        </TableCell>
    );
}