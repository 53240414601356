import { cloneDeep } from "lodash";
import { useState } from "react";

export function useDeepState<T>(initial: T | undefined = undefined): [T, (data: T) => void] {
    const [data, setData] = useState(initial);

    return [data, setDeepData];

    function setDeepData(data: T) {
        setData(cloneDeep(data));
    }
}   