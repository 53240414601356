import { Alert, Box, Stack } from "@mui/material";
import { FileIcon } from "components/FileIcon";

export function FileFallback({ name, message }: { name: string, message?: string; }) {

    if (!message)
        return <FileIcon fileName={name} />

    return (
        <Stack spacing={1}>
            <Box margin="auto">
                <FileIcon fileName={name} />
            </Box>
            <Alert severity="info">{message}</Alert>
        </Stack>
    );
}