import useApiFetch from "./useApiFetch"
import { InputResultProps, InputProps, ResultFunctionsType, FileIdBody, FileQueryBody, MetadataKeyBody } from "./types";
import { BulkEditDto, CreateNewFileBody, DeleteFilesBody, FileDto, FilesDto, FileEditDto, NewFileDto, TenantConfigDto, FileDeletedFlag, FileLinks, GetFilesLinksBody, MetadataDto } from "types";

export default function useApi() {

    const { apiGet, apiPost, apiDelete } = useApiFetch();

    return {
        getFile,
        getFiles,
        createFile,
        postFile,
        softDeleteFiles,
        deleteFiles,
        restoreFiles,
        getFileLinks,
        getFilesLinks,
        uploadCompleted,
        uploadFailed,
        getMetadataValues,
        getMetadataKeys,
        postMetadatas,
        hello
    };

    function getFile({ input, onSuccess, onError }: InputResultProps<FileIdBody, FileDto>) {
        apiGet({
            path: `file/${input.fileId}`,
            onSuccess,
            onError: (error) => onError && onError(error?.message),
        });
    }

    function createFile({ input, onSuccess, onError }: InputResultProps<CreateNewFileBody, NewFileDto>) {
        apiPost({
            path: `file`,
            body: input,
            onSuccess,
            onError: (error) => onError && onError(error?.message),
        });
    }

    function postFile({ input, onSuccess, onError }: InputResultProps<FileEditDto, FileDto>) {
        apiPost({
            path: `file/${input?.id}`,
            body: input,
            onSuccess,
            onError: (error) => onError && onError(error?.message),
        });
    }

    function deleteFiles({ input, onSuccess, onError }: InputProps<DeleteFilesBody>) {
        apiDelete({
            path: "files/?softDelete=false",
            body: input,
            onSuccess,
            onError: (error) => onError && onError(error?.message),
            keepalive: true, // keep request alive after the browser exit the page
        });
    }
    function softDeleteFiles({ input, onSuccess, onError }: InputProps<DeleteFilesBody>) {
        apiDelete({
            path: "files",
            body: input,
            onSuccess,
            onError: (error) => onError && onError(error?.message),
            keepalive: true, // keep request alive after the browser exit the page
        });
    }

    function restoreFiles({ input, onSuccess, onError }: InputProps<DeleteFilesBody>) {
        apiPost({
            path: "files/restore",
            body: input,
            onSuccess,
            onError: (error) => onError && onError(error?.message),
            keepalive: true, // keep request alive after the browser exit the page
        });
    }

    function uploadCompleted({ input, onSuccess, onError }: InputProps<FileIdBody>) {
        apiPost({
            path: `file/${input.fileId}/uploadCompleted`,
            onSuccess,
            onError: (error) => onError && onError(error?.message),
        });
    }

    function uploadFailed({ input, onSuccess, onError }: InputProps<FileIdBody>) {
        apiPost({
            path: `file/${input.fileId}/uploadFailed`,
            onSuccess,
            onError: (error) => onError && onError(error?.message),
        });
    }

    function getFileLinks({ input, onSuccess, onError }: InputResultProps<FileIdBody, FileLinks>) {
        apiGet({
            path: `file/${input.fileId}/links`,
            onSuccess,
            onError: (error) => onError && onError(error?.message),
        });
    }

    function getFilesLinks({ input, onSuccess, onError }: InputResultProps<GetFilesLinksBody, FileLinks[]>) {
        apiPost({
            path: `files/links`,
            body: input,
            onSuccess,
            onError: (error) => onError && onError(error?.message),
        });
    }


    function getMetadataValues({ input, onSuccess, onError }: InputResultProps<MetadataKeyBody, string[]>) {
        apiGet({
            path: `metadata/values/key/${input.metadataKey}`,
            onSuccess,
            onError: (error) => onError && onError(error?.message),
        })
    }

    function getMetadataKeys({ onSuccess, onError }: ResultFunctionsType<MetadataDto[]>) {
        apiGet({
            path: "metadata/keys",
            onSuccess,
            onError: (error) => onError && onError(error?.message),
        })
    }

    function postMetadatas({ input, onSuccess, onError }: InputProps<BulkEditDto>) {
        apiPost({
            path: "metadata/bulk",
            body: input,
            onSuccess,
            onError: (error) => onError && onError(error?.message),
        })
    }

    function hello({ onSuccess, onError }: ResultFunctionsType<{ message: string }>) {
        apiGet({
            path: "hello",
            onSuccess,
            onError: (error) => onError && onError(error?.message),
        });
    }

    function getSearchQueryParam(input: FileQueryBody): URLSearchParams {
        const { sorts, filters, select, skip, take, deletedFlag } = input;

        const searchParams = new URLSearchParams();

        sorts.forEach((sort, i) => {
            searchParams.set(`sort[${i}].ascending`, sort.ascending.toString());
            searchParams.set(`sort[${i}].property`, sort.property);
        });

        filters.forEach((filter, i) => {
            searchParams.set(`filter[${i}].property`, filter.property);
            searchParams.set(`filter[${i}].operator`, filter.operator);
            filter.value.forEach((value, j) => searchParams.set(`filter[${i}].value[${j}]`, value))
        });

        select.forEach((val, i) => searchParams.set(`select[${i}]`, val));

        searchParams.set('skip', skip.toString());
        searchParams.set('take', take.toString());
        searchParams.set('deletedFlag', deletedFlag ?? FileDeletedFlag.No);

        return searchParams;
    }


    function getFiles({ input, onSuccess: _onSuccess, onError }: InputResultProps<FileQueryBody, FilesDto>) {

        const searchParams = getSearchQueryParam(input);

        apiGet({
            path: `files?${searchParams.toString()}`,
            onSuccess,
            onError: (error) => onError && onError(error?.message),
        });

        function onSuccess(dto: FilesDto) {
            _onSuccess({
                files: (dto.files || []).map(f => ({
                    id: f.id,
                    name: f.name,
                    ...f
                })),
                count: dto.count
            });
        }
    }
}
