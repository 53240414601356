import { Box } from "@mui/material";
import { useKeyEvent } from "hooks";

export function Fullscreen({ show, onClose, children }: { show?: boolean, onClose?: () => void, children: JSX.Element }) {
    useKeyEvent({
        keyEvents: [{ key: "Escape", onKeydown: () => onClose() }]
    });

    if (!show) return null;

    return (
        <Box width="100%" position="absolute" height="100%" zIndex={5} sx={(theme) => ({ background: theme.palette.background.paper })}>
            {children}
        </Box>
    );
};