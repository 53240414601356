import { Typography } from "@mui/material";
import { GridCellParams, GridColDef } from "@mui/x-data-grid";
import { FileStatusDisplay } from "components/FileStatusDisplay";
import TooltipLink from "components/Tooltip/TooltipLink";
import { getMetadataFormatedValue } from "components/MetadataTable/MetadataValueField";
import { uniqBy } from "lodash";
import { useMemo, } from "react";
import { FileStatus, File, Column } from "types";


export type useGridColumnsProps = {
    onNameClick: (name: File) => void;
    columns: Column[],
    hiddenColumns?: Column[],
};

export function useGridColumns({ onNameClick, columns = [], hiddenColumns = [] }: useGridColumnsProps) {

    const gridColumns = useMemo(getColumns, [columns, hiddenColumns]);

    return { gridColumns };

    function getColumns() {

        var gridColumns: (GridColDef & { show?: boolean })[] = [...columns, ...hiddenColumns]
            .map((column) => ({
                field: column.key,
                headerName: column.name,
                width: 200,
                editable: false,
                show: columns.some(x => x.key === column.key),
                renderCell: renderCell(column.key)
            }));

        return uniqBy(gridColumns, x => x.field);

        function renderCell(columnKey: string) {

            return (params: GridCellParams<string, File>) => {
                if (columnKey === "name")
                    return params.row.Status === FileStatus.Completed
                        ? <TooltipLink linkId="filenameLink" value={params.value} color="inherit" href="#" variant="body1" onClick={() => onNameClick && onNameClick(params.row)} />
                        : <Typography color="textSecondary">{params.value}</Typography>
                else if (columnKey === "Status")
                    return <FileStatusDisplay status={params.value as FileStatus} error={params.row.ProcessingError} />
                else
                    return getMetadataFormatedValue(columnKey, params.value);
            }
        }
    }
}
