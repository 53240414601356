import { Box, Grid, LinearProgress, Typography } from "@mui/material";
import { Group } from '@mui/icons-material';
import ActionTextField from './ActionTextField'
import { useIframeCommunication } from "hooks";
import { useEffect, useState } from "react";
import { EventType } from "enums/EventType";
import { LoaderContainer } from "utils";

export type FileShareVisualProps = {
    link?: string,
    error?: string
}

//TODO refactor the modal/action logic (Edit, Delete, Share)
export function FileShareVisual({ link, error }: FileShareVisualProps) {
    return (
        <Grid container direction="column" alignItems='center' spacing={2}>
            <Grid item>
                <Typography variant="h5">Copy link</Typography>
            </Grid>
            <Grid item alignItems='center' minHeight={80} display="flex" width="100%" gap={1} justifyContent="center">
                <LoaderContainer loaded={!!link} error={error} loadingElement={() => <Box width="100%"><LinearProgress /></Box>}>
                    <ActionTextField
                        label="URL"
                        variant="outlined"
                        fullWidth
                        value={link}
                        copy={true}
                    />
                </LoaderContainer>
            </Grid>
            <Grid item alignItems='center' display="flex" gap={1}>
                <Group />
                <Typography>Only users in this project can view this file</Typography>
            </Grid>
        </Grid>
    )
}

export type FileShareProps = {
    fileIds: string[]
}

type FileShareEvent = {
    payload: string[]
}

export default function FileShare({ fileIds: ids }: FileShareProps) {

    const { sendEvent } = useIframeCommunication();
    const [link, setLink] = useState<string>();
    const [error, setError] = useState<string>();
    useEffect(() => {
        getUrl(ids);
    }, [ids]);

    return (<FileShareVisual link={link} error={error} />)

    function getUrl(ids: string[]) {
        if (!isIframed())
            return setLink(document.location.href);
            
        const onEventError = (error: string) => {
            setError(error);
        }
        const timer = setTimeout(() => onEventError("Timeout"), 5000);
        const onEventSuccess = (payload: string) => {
            clearTimeout(timer);
            setLink(payload);
        }
        sendEvent<FileShareEvent, string>({ eventType: EventType.GetContentUrl, message: { payload: ids }, onSuccess: onEventSuccess, onError: onEventError });
    }

    function isIframed() {
        try {
            return window.self !== window.top;
        } catch (e) {
            return true;
        }
    }

}