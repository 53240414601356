import { GridView, ViewList, ZoomIn, ZoomOut } from "@mui/icons-material";
import { Box, Slider, Stack, ToggleButtonGroup } from "@mui/material";
import { RecycleBinButton } from "components/RecycleBinButton";
import TooltipButton, { TooltipButtonProps } from "components/Tooltip/TooltipButton";
import TooltipToggleButton from "components/Tooltip/TooltipToggleButton";
import ButtonGroup from '@mui/material/ButtonGroup';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DownloadIcon from '@mui/icons-material/Download';
import EditIcon from '@mui/icons-material/Edit';
import ShareIcon from '@mui/icons-material/Share';
import DeleteIcon from '@mui/icons-material/Delete';
import { File } from "types";

export type FileExplorerView = "table" | "gallery";

export type FileExplorerHeaderProps = {
    iconSize?: number,
    view?: FileExplorerView,
    selection?: File[],
    recyleBin?: boolean,
    upload?: boolean,
    deletion?: boolean,
    onIconSizeChange?: (newSize: number) => void,
    onViewChange?: (newView: FileExplorerView) => void,
    onUpload?: () => void,
    onDelete?: () => void,
    onRestore?: () => void,
    onEdit?: () => void,
    onDownload?: () => void,
    onShare?: () => void,
    onRecycleBinChange?: (recyleBinMode: boolean) => void,
};

export function FileExplorerHeader({
    view,
    selection = [],
    iconSize,
    recyleBin,
    upload,
    deletion,
    onIconSizeChange,
    onViewChange,
    onUpload = () => { },
    onEdit = () => { },
    onDelete = () => { },
    onDownload = () => { },
    onRestore = () => { },
    onShare = () => { },
    onRecycleBinChange = () => { }
}: FileExplorerHeaderProps) {

    return (
        <Box justifyContent="space-between" alignItems="center" display="flex" width="100%">
            <Stack direction="row" spacing={1} alignItems="center">
                {upload && !recyleBin && <StyledTooltipButton
                    variant="outlined"
                    startIcon={<CloudUploadIcon />}
                    buttonId="upload"
                    onClick={() => onUpload()}
                />}
                <ButtonGroup variant="outlined">
                    <StyledTooltipButton
                        startIcon={<DownloadIcon />}
                        buttonId="downloadAll"
                        disabled={selection.length === 0}
                        onClick={() => onDownload()}
                    />
                    {deletion && recyleBin && <StyledTooltipButton
                        buttonId="restoreAll"
                        disabled={selection.length === 0}
                        onClick={() => onRestore()}
                    />}
                    {!recyleBin && <StyledTooltipButton
                        startIcon={<EditIcon />}
                        buttonId="editAll"
                        disabled={selection.length === 0}
                        onClick={() => onEdit()}
                    />}
                    {!recyleBin && <StyledTooltipButton
                        startIcon={<ShareIcon />}
                        buttonId="shareAll"
                        onClick={() => onShare()}
                    />}
                    {deletion && !recyleBin && <StyledTooltipButton
                        startIcon={<DeleteIcon />}
                        buttonId="deleteAll"
                        disabled={selection.length === 0}
                        onClick={() => onDelete()}
                    />}
                </ButtonGroup>
                <RecycleBinButton showDeleted={recyleBin} onChange={onRecyleBinClick} />
                <ToggleButtonGroup value={view || "table"} onChange={onViewToggleChange} exclusive size="small" >
                    <TooltipToggleButton groupId="explorerView" value="table" sx={{ padding: "5px" }}>
                        <ViewList sx={{ fontSize: 20 }} />
                    </TooltipToggleButton>
                    <TooltipToggleButton groupId="explorerView" value="gallery" sx={{ padding: "5px" }}>
                        <GridView sx={{ fontSize: 20 }} />
                    </TooltipToggleButton>
                </ToggleButtonGroup>
                {view === "gallery" && <Stack spacing={2} direction="row" alignItems="center" width="200px">
                    <ZoomOut sx={(theme) => ({ color: theme.palette.text.primary })} />
                    <Slider aria-label="Zoom" value={iconSize} onChange={handleIconSize} min={100} max={600} />
                    <ZoomIn sx={(theme) => ({ color: theme.palette.text.primary })} />
                </Stack>}
            </Stack>

        </Box >
    );

    function handleIconSize(event: any, value: number | number[]) {
        onIconSizeChange(value as number);
    }

    function onViewToggleChange(event: any, newView: FileExplorerView) {
        if (!newView) return;
        onViewChange(newView);
    }

    function onRecyleBinClick() {
        onRecycleBinChange(!recyleBin)
    }

    function StyledTooltipButton(props: TooltipButtonProps) {
        return <TooltipButton size="small" disableElevation {...props} />
    }
}
