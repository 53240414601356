import { Alert, Box, Typography } from '@mui/material';
import { MetadataTable } from 'components/MetadataTable';
import { Metadata } from 'types';
import { ButtonBar } from './ButtonBar';

export type MutliFileEditData = {
    metadatas?: Metadata[];
};

export type MultiFileEditFormProps = {
    data: MutliFileEditData;
    onChange?: (data: MutliFileEditData) => void;
    isSaving?: boolean;
    onSave?: () => void;
    onCancel?: () => void;
};

export function MultiFileEditForm({ data, onChange, isSaving, onSave, onCancel }: MultiFileEditFormProps) {

    const noData = data?.metadatas?.length <= 0;

    return (
        <>
            <Typography variant="h5">Metadata</Typography>

            <Box m={1}>
                <Alert severity="info">Metadata will be added to the selected files. This will not overwrite existing metadata</Alert>
            </Box>

            <Box overflow="auto" maxHeight="35vh">
                <MetadataTable metadatas={data?.metadatas} disabled={isSaving} onChange={(metadatas) => onChange({ metadatas })} />
            </Box>
            <ButtonBar
                save={onSave && {
                    onClick: onSave,
                    loading: isSaving,
                    disabled: noData,
                }}
                cancel={onCancel && {
                    onClick: onCancel,
                    disabled: isSaving
                }} />
        </>
    );
}