import { useEffect } from "react";

export type useKeyEventProps = {
    keyEvents: {
        key: string,
        onKeydown: (event: KeyboardEvent) => void;
    }[],
    focusElement?: HTMLElement;
};

export function useKeyEvent({ keyEvents, focusElement }: useKeyEventProps) {

    useEffect(() => {
        window.addEventListener("keydown", onKeydownEvent);
        return () => window.removeEventListener("keydown", onKeydownEvent);
    }, []);

    function onKeydownEvent(event: KeyboardEvent) {

        var keyEvent = keyEvents.find(x => x.key === event.key)
        
        if (!keyEvent) return;

        if ((focusElement && !document.activeElement.contains(focusElement)) === false) return;

        if (!keyEvent.onKeydown)
            console.warn(`No 'onKeydown' callback defined for KeyEvent with code '${event.key}`);

        keyEvent.onKeydown(event);
    }
}
