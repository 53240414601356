import { Identity } from "hooksuseIdentity";
import { sessionStorageUtility } from "utils/storage";

export function useStorageIdentity() {

    const key = "dt-cm-identityToken";

    return {
        getIdentity,
        setIdentity
    };

    function getIdentity() {
        const itemString = sessionStorageUtility.getItem(key);

        if (!itemString)
            return null;

        const item = JSON.parse(itemString);

        if (item?.decoded?.exp * 1000 >= Date.now())
            return item as Identity;

        return null;
    }

    function setIdentity(token: Identity) {
        sessionStorageUtility.setItem(key, JSON.stringify(token));
    }
}