import { useContext, useState } from "react"
import { useApi } from "hooks";
import { partition } from "lodash";
import { isDeletable } from "utils/functions";
import { LoaderContainer } from "utils";
import { SelectionContext } from "context";
import { FileDeleteForm } from "./ui/FileDeleteForm";

export type FileDeleteProps = {
    onSuccess?: () => void,
    onCancel?: () => void,
}

export default function FileDelete({ onSuccess, onCancel }: FileDeleteProps) {
    const api = useApi();
    const [state, setState] = useState<{ error?: string, isDeleting?: boolean }>({});

    const selectionFiles = useContext(SelectionContext).selection;

    const [deletableFiles, undeletablesFiles] = partition(selectionFiles, isDeletable);

    return (
        <LoaderContainer loaded={true} error={state?.error}>
            <FileDeleteForm deletableFiles={deletableFiles} undeletableFiles={undeletablesFiles} onCancel={onCancel} onDelete={onDelete} isDeleting={state?.isDeleting} />
        </LoaderContainer>
    );

    function onDelete() {
        setState({ ...state, isDeleting: true });
        api.softDeleteFiles({
            input: { fileIds: deletableFiles.map(x => x.id) },
            onError: (error) => {
                setState({ ...state, error: `Failed to delete file: ${error}` });
            },
            onSuccess: () => onSuccess && onSuccess()
        })
    }
}