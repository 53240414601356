import { Box, Chip, Tooltip } from "@mui/material";
import { CheckCircle } from "@mui/icons-material"
import { FileStatus } from "types"
import { useMemo } from "react";

export interface FileStatusDisplayProps {
    status: FileStatus;
    progress?: number;
    error?: string;
}

export const FileStatusDisplay = ({ status, progress, error }: FileStatusDisplayProps) => {
    const tooltip = useMemo(() => getTooltip(status, error), [status, error]);

    return (
        <Tooltip title={tooltip} placement="top">
            <div> 
                <FileStatusValue status={status} progress={progress} />
            </div>
        </Tooltip>
    );
}

function FileStatusValue({status, progress}: {status: FileStatus, progress?: number}) {
    switch (status) {
        case FileStatus.UploadFailed:
        case FileStatus.ProcessingError:
            return <Chip size="small" label="Error" color="error" />;
        case FileStatus.Pending:
            return <Chip size="small" label="Queued" variant="outlined" />;
        case FileStatus.Uploading:
            const label = progress ? `Uploading - ${Math.floor(progress)} %` : "InProgress";
            return <Chip size="small" label={label} color="primary" variant="outlined" />;
        case FileStatus.Uploaded:
            return <Chip size="small" label="Uploaded" variant="outlined" />;
        case FileStatus.Processing:
            return <Chip size="small" label="Processing" color="primary" variant="outlined" />;
        case FileStatus.Completed:
            return <Box color="green"><CheckCircle /></Box>
        default:
            return null;
    }
}

function getTooltip(status: FileStatus, error: string) {
    switch (status) {
        case FileStatus.UploadFailed:
            return "An unexpected error occured while uploading the file";
        case FileStatus.ProcessingError:
            return error || "An unexpected error occured while processing the file";
        case FileStatus.Pending:
            return "The file is waiting to be processed before it become available to use";
        case FileStatus.Uploading:
            return "The file is being uploaded. Please wait for this to complete.";
        case FileStatus.Uploaded:
            return "The file has been successfully uploaded";
        case FileStatus.Processing:
            return "We are currently working on making the file available to use. The file type and size may impact the process duration";
        case FileStatus.Completed:
            return "The file has been successfully uploaded and processed. It is now available to use";
        default:
            return null;
    }
}
