import { MultiFileEdit } from "./MultiFileEdit";
import { SingleFileEdit } from "./SingleFileEdit"

export type FileEditProps = {
    fileIds: string[],
    onSuccess?: () => void,
    onCancel?: () => void,
}

export function FileEdit(props: FileEditProps) {

    const { fileIds } = props;

    if (fileIds.length == 0)
        return null

    if (fileIds.length == 1)
        return <SingleFileEdit fileId={fileIds[0]} {...props} />

    return <MultiFileEdit {...props} />
}