

/*eslint unicode-bom: ["error", "always"]*/
//Auto generated scripts from 'Beca.Content.Typescript' using 'Typewriter'




export enum FilterOperation {
        
    Contains = "Contains",    
    Equals = "Equals",    
    StartsWith = "StartsWith",    
    EndsWith = "EndsWith",    
    IsEmpty = "IsEmpty",    
    IsNotEmpty = "IsNotEmpty"
}
