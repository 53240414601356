

/*eslint unicode-bom: ["error", "always"]*/
//Auto generated scripts from 'Beca.Content.Typescript' using 'Typewriter'




export enum FileType {

    Image = "Image",
    Video = "Video",
    Audio = "Audio",
    PointCloud = "PointCloud",
    Pdf = "Pdf"
}
