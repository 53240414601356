import { TenantProvider, ConfigProvider, BusProvider, FileLinksProvider, MetadataKeysProvider, SelectionProvider, FilesProvider } from 'context';
import { BrowserRouter, Routes, Route, useParams, } from "react-router-dom";
import { IntlProvider } from "react-intl";
import Home from "pages/Home";
import Documents from "pages/Documents";
import Uploader from "pages/Uploader";
import NoTenant from "pages/NoTenant";
import messages_en from "./lang/en.json"
import ThemeUpdater from 'theme/ThemeUpdater';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import { ThemeSwitcherProvider } from 'react-css-theme-switcher';

const client = new QueryClient();
const themes = {
  dark: `${process.env.PUBLIC_URL}/dark-theme.css`,
  light: `${process.env.PUBLIC_URL}/light-theme.css`,
};


function App() {
  return (
    <Router />
  );
}

function Router() {

  const hostRoute = process.env.REACT_APP_HOST_ROUTE;

  return (
    <BrowserRouter basename={hostRoute}>
      <Routes>
        <Route path={`/:tenantPath/documents`} element={
          <Contexts>
            <Documents />
          </Contexts>
        } />
        <Route path={`/:tenantPath/uploader`} element={
          <Contexts>
            <Uploader />
          </Contexts>
        } />
        <Route path={`/:tenantPath`} element={
          <Contexts>
            <Home />
          </Contexts>
        } />
        <Route path={hostRoute} element={
          <NoTenant />
        } />
        <Route element={
          <NoTenant />
        } />
      </Routes>
    </BrowserRouter>
  );
}

function Contexts({ children }: { children: JSX.Element }) {

  const { tenantPath } = useParams<{ tenantPath: string }>();

  return (
    <QueryClientProvider client={client}>
      <TenantProvider tenantPath={tenantPath}>
        <ConfigProvider>
          <ThemeSwitcherProvider themeMap={themes} defaultTheme="light" insertionPoint="styles-insertion-point">
            <ThemeUpdater>
              <MetadataKeysProvider>
                <FileLinksProvider>
                  <BusProvider>
                    <FilesProvider>
                      <SelectionProvider>
                        <IntlProvider locale='en' messages={messages_en}>
                          {children}
                        </IntlProvider>
                      </SelectionProvider>
                    </FilesProvider>
                  </BusProvider>
                </FileLinksProvider>
              </MetadataKeysProvider>
            </ThemeUpdater>
          </ThemeSwitcherProvider>
        </ConfigProvider>
      </TenantProvider>
    </QueryClientProvider>
  )
}

export default App;
