import { useEffect, useState } from "react";
import { makeStyles } from 'tss-react/mui';
import React from "react";
import { Box } from "@mui/material";

export type ImageProps = {
    url: string,
    fallbackUrl?: string,
    name?: string,
    onClick?: () => void,
    onError?: () => void,
    onFallbackError?: () => void,
    onSuccess?: () => void,
    cursor?: string,
}

export const useStyles = makeStyles()(() => ({
    image: {
        display: "block",
        margin: "auto",
        maxHeight: '100%',
        maxWidth: '100%',
        objectFit: "contain",
    },
}));

export const Image = React.memo(Image_Internal);

function Image_Internal({ url, fallbackUrl, name, onClick, onError, onFallbackError, onSuccess, cursor }: ImageProps) {

    const { classes } = useStyles();

    const [useFallback, setFallback] = useState(false);

    useEffect(() => setFallback(false), [url, fallbackUrl]);

    const link = useFallback ? fallbackUrl : url;

    return <Box height="100%" display="flex">
        <img
            alt={name}
            src={link}
            onLoad={() => onSuccess && onSuccess()}
            onError={onLoadError}
            onClick={() => onClick && onClick()}
            className={`${classes.image} image`}
            style={cursor ? { cursor } : {}}
        />
    </Box>

    function onLoadError() {
        if (useFallback || url === fallbackUrl)
            onFallbackError && onFallbackError()
        else {
            setFallback(true)
            onError && onError();
        }
    }
}
