
/**
 * DO NOT ALTER: This component is extracted from the legacy @beca/common-react
 * Technical Debt item: #86766
 */

export interface FetchParams extends RequestInit {
    url?: string,
    body?: any,
    bearer?: string,
    headers?: Headers,
    onSuccess?: (data: any) => void,
    onError?: (error: FetchError) => void
}

export interface FetchError {
    message: string,
    status?: number,
    statusText?: string
}

export default function useFetchHook() {

    function useFetch(fetchParams: FetchParams) {

        const { url, body, bearer, onSuccess, onError } = fetchParams;

        fetchParams.headers = fetchParams.headers || new Headers();
        fetchParams.headers.append("Content-Type", "application/json");

        if (bearer)
            fetchParams.headers.append("Authorization", `Bearer ${bearer}`);

        if (body) {
            fetchParams.body = JSON.stringify(body);
        }

        fetch(url || "", fetchParams)
            .then(response => {
                if (response.ok) {
                    if (onSuccess) {
                        const contentType = response.headers.get("content-type");
                        if (contentType && contentType.indexOf("application/json") !== -1)
                            response.json().then(onSuccess)
                        else
                            response.text().then(onSuccess)
                    }
                }
                else {
                    if (onError) {
                        response.text().then(errorMessage =>
                            onError({
                                ...response,
                                message: `An unexpected error occured (Error ${response.status} ${response.statusText}) ${errorMessage}`
                            })
                        );
                    }
                }
            })
            .catch((error: Error) => {
                if (onError) {
                    const fetchError: FetchError = { ...error };
                    onError(fetchError);
                }
            });
    }

    return { useFetch };
}