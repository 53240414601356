import { createContext, useContext, useEffect, useState } from "react";
import { FileFilterDto as Filter, FileSortDto as Sort, FilterOperation, FileDeletedFlag, File, Pagination, Column, FileStatusUpdate, FilesHubProxyMethods, FileStatus } from "types";
import { useApi, useSignalR } from "hooks";
import { GridFilterModel, GridSortModel, } from "@mui/x-data-grid";
import { ConfigContext } from "context";
import type { FilesDto } from "hooks/useApi/types";
import { ErrorContainer } from "utils";
import { clone } from "lodash";

interface FilesContextState {
    data: FilesData,
    query: FileQuery,
    refresh: () => void,
    setQuery: (query: FileQuery) => void,
    setPartialQuery: (query: Partial<FileQuery>) => void,
}

const contextDefaultValues: FilesContextState = {
    data: null,
    query: {
        columns: [{ key: "Name", name: "Name" }, { key: "Status", name: "Status" }],
        recycled: false,
        pageIndex: 0,
        pageSize: 25
    },
    refresh: () => { },
    setQuery: () => { },
    setPartialQuery: () => { },
};

export const FilesContext = createContext<FilesContextState>(
    contextDefaultValues
);

type FilesProviderProps = {
    children: JSX.Element,
    initialData?: FilesData
}

type FileQuery = {
    sortModel?: GridSortModel,
    filterModel?: GridFilterModel,
    columns?: Column[],
    recycled?: boolean,
    pageIndex: number,
    pageSize: number
}

export type FilesData = {
    files: File[],
    page: Pagination,
    loading: boolean,
}

function getDefaultQuery(columns: Column[]) {
    const query = clone(contextDefaultValues.query);

    if (columns)
        query.columns = columns;

    return query;
}

export function FilesProvider({ children, initialData = contextDefaultValues.data }: FilesProviderProps) {

    const { filters, sorts, fileType, columns } = useContext(ConfigContext).config.documents;

    const api = useApi();

    const [data, setData] = useState<FilesData>(initialData);
    const [error, setError] = useState<string>(null);
    const [query, setQuery] = useState<FileQuery>(getDefaultQuery(columns));

    useEffect(queryFiles, [query]);

    useSignalR("files", { [FilesHubProxyMethods.UpdateFileStatus]: UpdateFileStatus })

    return (
        <FilesContext.Provider
            value={{
                data,
                query,
                refresh,
                setQuery,
                setPartialQuery
            }}
        >
            <ErrorContainer error={error}>
                {children}
            </ErrorContainer>
        </FilesContext.Provider >
    );

    function refresh() {
        setQuery(clone(query));
    }

    function UpdateFileStatus(message: FileStatusUpdate) {
        setData(oldData => {
            return { ...oldData, files: oldData.files.map(f => f.id === message.id ? { ...f, Status: message.status } : f) };
        });
        if (message.status === FileStatus.Completed)
            refresh();
    
    }

    function setPartialQuery(partial: Partial<FileQuery>) {
        setQuery({ ...query, ...partial });
    }

    function queryFiles() {

        if (initialData !== contextDefaultValues.data)
            return

        const tableFilters = query.filterModel ? query.filterModel?.items
            ?.filter(filter => filter.value || filter.operatorValue === FilterOperation.IsEmpty || filter.operatorValue === FilterOperation.IsNotEmpty)
            .map(item => ({
                property: item.columnField,
                operator: item.operatorValue as FilterOperation,
                value: [item.value]
            } as Filter)) : [];

        const fileTypeFilter: Filter[] = fileType
            ? [{ property: "Type", operator: FilterOperation.Equals, value: [fileType] }]
            : [];

        const combinedFilters = [...filters, ...tableFilters, ...fileTypeFilter];

        const tableSorts: Sort[] = query.sortModel?.map(sort => ({ ascending: sort.sort === "asc", property: sort.field }))

        const combinedSorts = [...(sorts || []), ...(tableSorts || [])];

        const deletedFlag = query.recycled ? FileDeletedFlag.Yes : FileDeletedFlag.No;

        setData({ ...data, loading: true });

        api.getFiles({
            input: {
                select: query.columns.map(column => column.key).concat(["Type"]),
                skip: query.pageSize * query.pageIndex,
                take: query.pageSize,
                filters: combinedFilters,
                sorts: combinedSorts,
                deletedFlag: deletedFlag
            },
            onSuccess: processMetadata,
            onError: setError
        });

        function processMetadata(response: FilesDto) {
            const files = response.files.map(file => {
                file.id = file.Id;
                delete file.Id;
                file.name = file.Name;
                return file;
            })
            setData({
                files,
                loading: false,
                page: {
                    index: query.pageIndex,
                    size: query.pageSize,
                    total: response.count
                }
            });
        }
    }
};
export default FilesProvider;
