import { ToggleButtonGroup } from "@mui/material";
import { Image as ImageIcon } from '@mui/icons-material';
import PanoramaPhotosphereIcon from '@mui/icons-material/PanoramaPhotosphere';
import { FileViewType } from "./FileView";
import TooltipToggleButton from "components/Tooltip/TooltipToggleButton";

export function FileViewSwitcher({ fileViewType, onChange }: { fileViewType: FileViewType; onChange: (type: FileViewType) => void; }) {

    if (fileViewType !== "image" && fileViewType !== "image-panorama")
        return null;

    return (
        <ToggleButtonGroup
            className="fileView-switcher"
            color="primary"
            sx={(theme) => ({ background: theme.palette.background.paper })}
            size="small"
            value={fileViewType}
            exclusive
            onChange={(e, v) => v && onChange(v)}
        >
            <TooltipToggleButton groupId="imageView" value="image" sx={{ padding: "3px" }}> <ImageIcon /> </TooltipToggleButton>
            <TooltipToggleButton groupId="imageView" value="image-panorama" sx={{ padding: "3px" }}> <PanoramaPhotosphereIcon /> </TooltipToggleButton>
        </ToggleButtonGroup>
    );
}
