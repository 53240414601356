import { Box, Checkbox, Theme } from "@mui/material";
import { makeStyles } from 'tss-react/mui';
import { alpha } from '@mui/material/styles';
import React from "react";

export const useStyles = makeStyles()(({ palette }: Theme) => ({
    galleryItem: {
        display: "flex",
        boxSizing: "border-box",
        flexDirection: "column",
        width: "min-content",
        position: "relative",
        '&:hover': {
            backgroundColor: alpha(palette.action.hover, palette.action.focusOpacity),
        }
    },
    selected: {
        backgroundColor: alpha(palette.primary.dark, palette.action.selectedOpacity),
        '&:hover': {
            backgroundColor: alpha(palette.primary.dark, palette.action.focusOpacity),
        }
    },
}));

export type GalleryItemProps<TItem extends { id: string }> = {
    item?: TItem,
    selected?: boolean,
    size?: number
    onClick?: () => void,
    onTickboxToggle?: (next: boolean) => void,
    bodyComponent?: (item: TItem) => JSX.Element,
    footerComponent?: (item: TItem) => JSX.Element,
}

export const GalleryItem = React.memo(GalleryItem_);

function GalleryItem_<TItem extends { id: string }>({ item, selected, onTickboxToggle, onClick, size = 150, bodyComponent, footerComponent }: GalleryItemProps<TItem>) {

    const { classes, cx } = useStyles();

    if (!item) return null;

    return (
        <Box className={cx(classes.galleryItem, { [classes.selected]: selected })} onClick={onCardClick} data-id={item.id}>
            <Box>
                <Checkbox onClick={onCheckBoxChange} checked={selected} size="small" />
            </Box>
            <Box width={`${size}px`} height={`${size}px`} display="flex" alignItems="center" justifyContent="center" padding={1}>
                {bodyComponent && bodyComponent(item)}
            </Box>
            <Box width={`${size}px`} textAlign="center" padding={1}>
                {footerComponent && footerComponent(item)}
            </Box>
        </Box>
    );

    function onCheckBoxChange(e: React.MouseEvent<HTMLButtonElement>) {
        if (onTickboxToggle)
            onTickboxToggle(!selected);
        e.stopPropagation();
    }

    function onCardClick(e: React.MouseEvent<HTMLInputElement>) {
        if (onClick)
            onClick();
        e.stopPropagation();
    }
}
