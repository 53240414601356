import { useRef, useEffect } from "react";

export type useSelectionProps<TItem extends { id: string }> = {
    items: TItem[],
    selectedItems: TItem[],
}

export function useSelection<TItem extends { id: string }>({ items, selectedItems }: useSelectionProps<TItem>) {

    const selectedItemsRef = useRef(selectedItems);
    useEffect(() => { selectedItemsRef.current = selectedItems }, [selectedItems]);

    return { getPrevious, getNext };

    function getPrevious() {
        const itemIndex = getLastSelectedItemIndex();

        if (itemIndex === -1) return; //can happen if the last selection was done in a different page

        if (itemIndex === 0) return;
        return items[itemIndex - 1];
    }

    function getNext() {
        const itemIndex = getLastSelectedItemIndex();
        if (itemIndex === -1) return; //can happen if the last selection was done in a different page

        if (itemIndex === items.length - 1) return;
        return items[itemIndex + 1];
    }

    function getLastSelectedItemIndex() {
        if (selectedItemsRef.current.length === 0) return;

        const lastSelectedItem = selectedItemsRef.current[selectedItemsRef.current.length - 1];

        return items.findIndex(x => x.id === lastSelectedItem.id);
    }
}