import { Button } from "@mui/material";
import { Add } from "@mui/icons-material"

export type NewRowProps = { 
    disabled: boolean, 
    hidden: boolean,
    onAdd:() => void 
}

export default function NewRow({disabled, hidden, onAdd}:NewRowProps) {
    if (hidden) return null;

    return (
        <caption>
            <Button
                disabled={disabled}
                onClick={onAdd}
                variant="contained"
                color="primary"
                startIcon={<Add />}>
                New Metadata
            </Button>
        </caption>
    );
}