

/*eslint unicode-bom: ["error", "always"]*/
//Auto generated scripts from 'Beca.Content.Typescript' using 'Typewriter'




export enum FileDeletedFlag {
        
    No = "No",    
    Yes = "Yes",    
    Both = "Both"
}
