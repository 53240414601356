import { createContext, useRef } from "react";
import { FileLinks, Dictionary } from "types";
import { useApi } from "hooks"

interface FileLinksContextState {
    getFileLinks: (props: GetFileLinksProps) => void,
}

const contextDefaultValues: FileLinksContextState = {
    getFileLinks: () => { },
};

export const FileLinksContext = createContext<FileLinksContextState>(
    contextDefaultValues
);

export type GetFileLinksProps = {
    fileIds: string[],
    onSuccess: (filesLinks: FileLinks[]) => void,
    onError: (error: string) => void
}

export function FileLinksProvider({ children }: { children: JSX.Element }) {

    const items = useRef<Dictionary<FileLinks>>({});
    const api = useApi();

    return (
        <FileLinksContext.Provider value={{ getFileLinks: queryFileLinks }} >
            {children}
        </FileLinksContext.Provider>
    );

    function queryFileLinks({ fileIds, onSuccess, onError }: GetFileLinksProps) {

        const notAvailableItems = fileIds.filter((x) => !isItemAvailable(x));

        if (notAvailableItems.length === 0) {
            returnData();
            return;
        }

        api.getFilesLinks({
            input: { fileIds: notAvailableItems },
            onSuccess: (filesLinks) => {
                filesLinks.forEach(x => items.current[x.id] = x);
                returnData();
            },
            onError
        });

        function isItemAvailable(fileId: string): boolean {
            const item = items.current[fileId];
            return (item && new Date(item.expiryDateUtc) > new Date())
        }

        function returnData() {
            return onSuccess(fileIds.map(x => items.current[x]));
        }
    };
}

export default FileLinksProvider;

