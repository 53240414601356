import { ReactNode, useCallback, useState } from "react";
import { Image } from "components/Image";
import { Box } from "@mui/material";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";

export type ImageViewerProps = {
    url: string,
    fullsizeUrl?: string,
    name?: string,
    allowZoomPanPinch?: boolean,
    cursor?: string,
    onClick?: () => void,
    onSuccess?: () => void,
    onError?: () => void,
}

export function ImageViewer({ url, fullsizeUrl, name, allowZoomPanPinch, cursor, onClick, onSuccess, onError }: ImageViewerProps) {

    const ZoomPanPinchContainer = useCallback(ZoomPanPinchContainer_Internal, [allowZoomPanPinch, url, fullsizeUrl]);

    return (
        <ZoomPanPinchContainer>
            <Image
                url={url}
                fallbackUrl={fullsizeUrl}
                name={name}
                onFallbackError={() => onError && onError()}
                onClick={onClick}
                onSuccess={onSuccess}
                cursor={cursor}
            />
        </ZoomPanPinchContainer>
    );

    function ZoomPanPinchContainer_Internal({ children }: { children: JSX.Element }) {

        if (!allowZoomPanPinch) return children;

        return (
            <ZoomPanPinchImageContainer url={url}>
                {children}
            </ZoomPanPinchImageContainer>
        );
    }
}

function ZoomPanPinchImageContainer({ url, children }: { url: string, children: ReactNode }) {

    const [loading, setLoading] = useState(true);

    //preload the image, so TransformWrapper will instantiate TransformComponent with the right dimension & placement
    if (loading) {
        return <Image url={url} onSuccess={() => setLoading(false)} />
    }

    return (
        <TransformWrapper centerOnInit={true}>
            <TransformComponent wrapperStyle={{ width: "100%", height: "100%", cursor: "move" }} contentStyle={{ height: "100%" }}>
                <Box width="100%" height="100%">
                    {children}
                </Box>
            </TransformComponent>
        </TransformWrapper>
    );
}
