import { IconButton , Snackbar, TextField, TextFieldProps} from "@mui/material";
import { ContentCopy } from '@mui/icons-material';
import { useState } from "react";
export type ActionTextFieldProps = TextFieldProps & {copy?:boolean};

export default function ActionTextField(props: ActionTextFieldProps){

    const {copy, ...textFieldProps} = props;
    const [feedback, setFeedback] = useState<string>(null);

    return (
    <>
        <TextField {...textFieldProps} />
        {copy && 
            <IconButton onClick={copyLink}>
                <ContentCopy/>
            </IconButton>
        }
        <Snackbar
            open={feedback !== null}
            autoHideDuration={6000}
            onClose={() => setFeedback(null)}
            message={feedback}
        />
    </>
    );

    async function copyLink() {
        if (typeof textFieldProps.value === 'string'){
            await navigator.clipboard.writeText(textFieldProps.value);
            setFeedback("Copied to clipboard!");
        }
    }
}
