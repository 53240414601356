import { InputAdornment, TextField, TextFieldProps } from "@mui/material";
import { ChangeEvent, memo, } from "react";

export const FileNameField = memo(FileNameFieldInternal)

function FileNameFieldInternal(props: TextFieldProps) {

    const value = (props.value || "") as string;
    const onChange = props.onChange;

    let extension = "";
    let name = value;

    if (name.includes(".")) {
        extension = value.slice(value.lastIndexOf("."), value.length);
        name = value.slice(0, value.lastIndexOf("."));
    }

    name = removeNonAllowedCharacters(name);

    return (
        <TextField
            {...props}
            value={name}
            InputProps={{
                endAdornment: <InputAdornment position="end">{extension}</InputAdornment>,
            }}
            onChange={onNameChange} />
    );

    function onNameChange(event: ChangeEvent<HTMLTextAreaElement>) {
        event.target.value = removeNonAllowedCharacters(event.target.value) + extension;
        onChange && onChange(event);
    }
}

function removeNonAllowedCharacters(name: string = "") {
    return name.replace(/[^a-z0-9 ()_.-]/gi, '')
}