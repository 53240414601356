import { Box, Typography, Card, CardContent, CardActions, TablePagination, Theme } from "@mui/material";
import React, { memo } from "react";
import { GalleryItem } from "./GalleryItem";
import { useSelection, useKeyEvent } from "hooks";
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(({ spacing }: Theme) => ({
    root: {
        display: "flex",
        overflow: "auto",
        width: "100%",
        flexWrap: "wrap",
        alignContent: "start",
        gap: spacing(1)
    },
}));

export const Gallery = memo(Gallery_);

export type GalleryProps<TItem extends { id: string }> = {
    items?: TItem[];
    pageSize?: number;
    loading?: boolean;
    rowCount?: number;
    selectedItems?: TItem[];
    tileSize?: number;
    pageIndex?: number;
    bodyComponent?: (item: TItem) => JSX.Element,
    footerComponent?: (item: TItem) => JSX.Element,
    onSelection?: (items: TItem[]) => void;
    onPageChange?: (pageIndex: number) => void;
};

function Gallery_<TItem extends { id: string }>({ items = [], pageSize, rowCount, selectedItems = [], tileSize, pageIndex = 0, onSelection, onPageChange, bodyComponent, footerComponent }: GalleryProps<TItem>) {

    const { classes } = useStyles();

    const { getNext, getPrevious } = useSelection<TItem>({ items, selectedItems });

    useKeyEvent({
        keyEvents: [
            {
                key: "ArrowLeft",
                onKeydown: () => selectUsing(getPrevious)
            },
            {
                key: "ArrowRight",
                onKeydown: () => selectUsing(getNext)
            }
        ]
    });

    return (
        <Card sx={{ height: "100%", width: "100%", display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }} variant="outlined">
            <CardContent className={classes.root} onClick={onBackgroundClick} >
                {items?.map((item, index) => {
                    return <GalleryItem
                        key={index}
                        item={item}
                        size={tileSize}
                        selected={isSelected(item)}
                        onClick={() => onClick(item)}
                        bodyComponent={bodyComponent}
                        footerComponent={footerComponent}
                        onTickboxToggle={(next) => onTickboxToggle(item, next)} />;
                })}
            </CardContent>
            <CardActions>
                <Box justifyContent="space-between" alignItems="center" flexDirection="row-reverse" display="flex" width="100%">
                    <TablePagination
                        component="div"
                        rowsPerPageOptions={[]}
                        page={pageIndex}
                        onPageChange={handlePageChange}
                        rowsPerPage={pageSize}
                        count={rowCount} />
                    {selectedItems.length > 0 && <Typography variant="body2"> {selectedItems.length} item{selectedItems.length > 1 ? "s" : ""} selected</Typography>}
                </Box>
            </CardActions>
        </Card>
    );

    function handlePageChange(event: React.MouseEvent<HTMLButtonElement>, pageIndex: number) {
        onPageChange(pageIndex);
    }

    function onBackgroundClick() {
        onSelection([]);
    }

    function isSelected(item: TItem) {
        return selectedItems.some(x => item.id === x.id);
    }

    function onClick(item: TItem) {
        onSelection([item]);
    }

    function onTickboxToggle(item: TItem, next: boolean) {
        if (next)
            return onSelection([...selectedItems, item]);
        return onSelection(selectedItems.filter(c => item.id !== item.id));
    }

    function selectUsing(getAction: () => TItem) {
        const newItem = getAction();
        if (newItem)
            onSelection([newItem]);
    }
}