

/*eslint unicode-bom: ["error", "always"]*/
//Auto generated scripts from 'Beca.Content.Typescript' using 'Typewriter'





export const FilesHubMethods = {
    Subscribe: "Subscribe",
    Unsubscribe: "Unsubscribe",
    SendFileStatusUpdate: "SendFileStatusUpdate"
}